import React, { Component } from 'react';
import * as am4core from "@amcharts/amcharts4/core";
import * as am4charts from "@amcharts/amcharts4/charts";
import am4themes_animated from "@amcharts/amcharts4/themes/animated";
am4core.addLicense("CH235960823")
am4core.useTheme(am4themes_animated);

const colorList = ["#FAB319", "#314BFD", "#C1D050", "#E0368C", "#2B4141", "#4E0110", "#9FFFF5", "#AA9ABA"];
var orangeList = ["#F2D896", "#F5D05D", "#EFB208", "#C89006", "#A16E00"]
var blueList = ["#E0E3F5", "#B4BBEE", "#6B81FF", "#354CE5", "#26328C"]
var greenList = ["#DFF1A6", "#CEDF4A", "#9CAF0A", "#5E7C12", "#055816"]
var pinkList = ["#FFDCED", "#FDA8CD", "#E17DAE", "#D0488B", "#AC1E63"]
var greyList = ["#C2D6D6", "#91B6B6", "#5A8787", "#395656", "#213131"]
var blackbeanList = ["#FD9BAE", "#FC4A6E", "#FB0E3D", "#A10222","#4E0110"]
var celesteList = ["#EBFFFD", "#C2FFF9", "#99FFF5", "#00F5DC", "#00B8A5"]
var grapList = ["#D6CEDE", "#C2B6CD", "#AA9ABA", "#8F79A4", "#665379"]
const colorShadeList = [orangeList,blueList,greenList,pinkList,greyList,blackbeanList,celesteList,grapList]


class RadarTimeline extends Component {
  
  componentDidMount() {
    var chart = am4core.create(this.props.id, am4charts.RadarChart);
    var currentYear = this.props.year;
    var strategicNameList = this.props.strategicNameList
    var strategics = this.props.data;

    chart.hiddenState.properties.opacity = 0;
    
    chart.startAngle = -180;
    chart.endAngle = 180;
    
    chart.padding(5,15,5,10)
    chart.radius = am4core.percent(65);
    chart.innerRadius = am4core.percent(40);
    
    // Year label goes in the middle
    var yearLabel = chart.radarContainer.createChild(am4core.Label);
    yearLabel.horizontalCenter = "middle";
    yearLabel.verticalCenter = "middle";
    yearLabel.fill = am4core.color("#673AB7");
    yearLabel.text = String(currentYear);
    
    // Category axis
    var categoryAxis = chart.xAxes.push(new am4charts.CategoryAxis());
    categoryAxis.renderer.grid.template.location = 0;
    categoryAxis.dataFields.category = "indicator_name";
    categoryAxis.tooltip.defaultState.properties.opacity = 0;

    var categoryAxisRenderer = categoryAxis.renderer;
    categoryAxisRenderer.minGridDistance = 10;
    categoryAxisRenderer.grid.template.radius = -10;
    categoryAxisRenderer.grid.template.strokeOpacity = 0.05;
    categoryAxisRenderer.grid.template.interactionsEnabled = false;
    categoryAxisRenderer.ticks.template.disabled = true;
    categoryAxisRenderer.axisFills.template.disabled = true;
    categoryAxisRenderer.line.disabled = true;

    var categoryAxisLabel = categoryAxisRenderer.labels.template;
    categoryAxisLabel.disabled = true;
    
    // Value axis
    var valueAxis = chart.yAxes.push(new am4charts.ValueAxis());
    valueAxis.min = 0;
    valueAxis.max = 5;
    valueAxis.strictMinMax = true;
    valueAxis.tooltip.defaultState.properties.opacity = 0;
    valueAxis.tooltip.animationDuration = 0;
    valueAxis.cursorTooltipEnabled = true;
    valueAxis.renderer.disabled = true;

    // Series
    var series = chart.series.push(new am4charts.RadarColumnSeries());
    series.columns.template.width = am4core.percent(100);
    series.columns.template.strokeOpacity = 0;
    series.dataFields.valueY = "kpi_score";
    series.dataFields.categoryX = "indicator_name";
    series.tooltip.getFillFromObject = false;
    series.tooltip.label.wrap = true;
    series.tooltip.label.maxWidth = 400;
    series.tooltip.label.fill = am4core.color("#000000");
    series.tooltipText = "{categoryX}: {valueY.value}";
    series.tooltip.background.fill = am4core.color("#D0D0D0");

    // Makes columns to be of a different color, depending on value
    series.columns.template.column.adapter.add("fill", (fill, target) => {
      var indicatorName = target.dataItem.categoryX;
      var selected = selectColorGroup(indicatorName)
      for(var i = 0; i < 5; i++){
        if(target.dataItem.valueY === i+1) {
          fill = am4core.color(selected[i]);
        }
      }
      return fill;
    });

    function selectColorGroup(indicatorName) {
      for(var i in strategics) {
        for(var j in strategics[i]) {
          if(strategics[i][j][0] === indicatorName){
            return colorShadeList[i]
          }
        }
      }
    }

    // Cursor
    var cursor = new am4charts.RadarCursor();
    chart.cursor = cursor;
    cursor.behavior = "zoomX";
    
    cursor.xAxis = categoryAxis;
    cursor.innerRadius = am4core.percent(40);
    cursor.lineY.disabled = true;
    
    cursor.lineX.fillOpacity = 0.1;
    cursor.lineX.fill = am4core.color("#000000");
    cursor.lineX.strokeOpacity = 0;
    cursor.fullWidthLineX = false;

    chart.data = generateRadarData();
    
    function generateRadarData() {
      var data = [];
      var i = 0;
      for (var strategic in strategics) {
        var strategicData = strategics[strategic];
        if(strategicData.length > 0){
          strategicData.forEach(indicator => {
            var rawDataItem = { "indicator_name": indicator[0] }
            for (var y = 1; y < indicator.length; y++) {
                rawDataItem["kpi_score"] = indicator[y];
            }
            data.push(rawDataItem);
          });
          createRange(strategicData, i);
          i++;
        }
      }
      return data;
    }

    function createRange(strategicData, index) {
      var axisRange = categoryAxis.axisRanges.create();
      axisRange.axisFill.interactionsEnabled = true;

      axisRange.text = strategicNameList[index];

      // First strategic
      axisRange.category = strategicData[0][0];
      // Last strategic
      axisRange.endCategory = strategicData[strategicData.length - 1][0];

      // Set color of Strategic Intent
      axisRange.axisFill.fill = am4core.color(colorList[index]);
      axisRange.grid.disabled = true;
      axisRange.label.interactionsEnabled = false;
      axisRange.label.bent = true;
      axisRange.label.fontSize = 8;

      chart.events.on("sizechanged", function(ev) {
        if(ev.target.contentWidth<400){
          axisRange.label.fontSize = 5;
        }
      })

      var axisFill = axisRange.axisFill;
      axisFill.innerRadius = -0.001; // almost the same as 100%, we set it in pixels as later we animate this property to some pixel value
      axisFill.radius = -25; // negative radius means it is calculated from max radius
      axisFill.fillOpacity = 1;
      axisFill.togglable = true;
      axisFill.showSystemTooltip = true;
      axisFill.readerTitle = "click to zoom";
      axisFill.cursorOverStyle = am4core.MouseCursorStyle.pointer;

      axisFill.events.on("hit", function (event) {
          var dataItem = event.target.dataItem;
          if (!event.target.isActive) {
              categoryAxis.zoom({ start: 0, end: 1 });
          }
          else {
              categoryAxis.zoomToCategories(dataItem.category, dataItem.endCategory);
          }
      })

      // Hover state
      var hoverState = axisFill.states.create("hover");
      hoverState.properties.innerRadius = -1;
      hoverState.properties.radius = -30;

      var axisLabel = axisRange.label;
      axisLabel.location = 0.5;
      axisLabel.fill = am4core.color("#ffffff");
      axisLabel.radius = 8;
      axisLabel.relativeRotation = 0;
    }
    this.chart = chart;
  }

  componentWillUnmount() {
    if (this.chart) {
      this.chart.dispose();
    }
  }

  render() { 
    return (
      <div className="chart" id={this.props.id} style={{ width: this.props.width, height: this.props.height, fontSize: "14px"}}></div>
    );
  }
}

export default RadarTimeline;