import React, { Component } from 'react';
import { Button, Modal, Form } from 'react-bootstrap';

class ModalSelectStrategic extends Component {
    constructor(props) {
        super(props);
        this.state = {
            strategicSelected: []
        }
    }

    handleCheckboxChange = (event) => {
        let newArray = [...this.state.strategicSelected, event.target.value];
        if (this.state.strategicSelected.includes(event.target.value)) {
        newArray = newArray.filter((strategic) => strategic !== event.target.value);
        }
        this.setState({
            strategicSelected: newArray
        });
    };

    handleFormSubmit = () => {
        this.state.strategicSelected.forEach(async(strategic,index) => {
            await localStorage.setItem(`strategic${index+1}`, strategic)
        });
        window.location.reload();
    }

    render(){
        return(
            <Modal {...this.props} centered>
                <Modal.Header closeButton>
                <Modal.Title>เลือกกลุ่มยุทธศาสตร์</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Form>
                    <Form.Label>เลือกกลุ่มยุทธศาสตร์ให้ครบ 4 กลุ่ม เพื่อแสดงแผนภูมิผลการประเมิน</Form.Label>
                    {this.props.strategicList.map((strategic,index) => {
                        return <Form.Group key={index}>
                            <Form.Check type="checkbox" onChange={this.handleCheckboxChange} checked={this.state.strategicSelected.includes(index.toString())} id={index+1} value={index} label={strategic}/>
                        </Form.Group>
                    })}
                    </Form>
                </Modal.Body>
                <Modal.Footer style={{justifyContent: "center"}}>
                    <Button 
                        className="btn-submit"
                        disabled={this.state.strategicSelected.length === 4 ? false : true} 
                        type="submit"
                        style={{width:"140px", height: "36px"}}
                        onClick={this.handleFormSubmit}
                    >
                        ตกลง
                    </Button>
                    <Button className="btn-cancel" style={{width:"140px", height: "36px"}} onClick={this.props.onHide}>
                        ยกเลิก
                    </Button>
                </Modal.Footer>
            </Modal>
        );
    }
}

export default ModalSelectStrategic;