import React, { useState } from 'react';
import { Alert } from 'react-bootstrap';
import { faInfoCircle } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

function AlertInfomation(props){
  const [show, setShow] = useState(true);
  if(show){
    return(
      <Alert className="mt-2" variant={props.type} onClose={() => setShow(false)} dismissible>
        { props.header ? 
        <Alert.Heading>
          <FontAwesomeIcon size="xs" className="alert-icon mr-2" icon={faInfoCircle} />
          {props.header}
          </Alert.Heading> : <></> }
        <p>
          {props.message}
        </p>
      </Alert>
    );
  }
  return <></>
}

export default AlertInfomation