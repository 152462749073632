import React, { Component } from 'react';
import { Button, Modal, Form, Card } from 'react-bootstrap';
import { faTimes } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Typeahead } from 'react-bootstrap-typeahead';

class ModalCompare extends Component {
  constructor(props) {
    super(props);
    this.state = {
        selected:[],
        arraySelect:[],
        option: this.props.modelData,
        optionMaster: this.props.modelData,
        year: this.props.year
    }
  }

  pushArray = (selected) => {
      if(selected.length !== 0){
      var joined = this.state.arraySelect.concat(selected[0]);
      this.setState({ 
          arraySelect: joined,
          selected: [],
          option : this.state.option.filter(item => item.slug !== selected[0].slug)
      })
      }
  };

  removeUniversity = (e) => {
      this.setState((prevState) => {
          return {option: prevState.option.concat(e), arraySelect : this.state.arraySelect.filter(item => item.slug !== e.slug) };
      })
  }

  submit = (selected) => {
    var university = []
    this.state.arraySelect.forEach((item)=>{
    university.push(item.slug)
    })
    var a = btoa(JSON.stringify({year: this.state.year, university: university, filter: []}))
    this.props.history.push(`/compare/${a}/true`)
  };

  clear = () => {
    this.setState((prevState) => {
      return {option: prevState.optionMaster, arraySelect : [] };
    })
  }

  render() {
    const { arraySelect, option } = this.state
    return (
        <Modal style={{borderRadius: "unset"}}
        {...this.props}
        size="lg"
        dialogClassName="modal-compare"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Header closeButton className="p-3">
          <Modal.Title id="contained-modal-title-vcenter">
          <img alt="" src={`/images/compare-icon.svg`} width="20px" height="auto"/>  รายการเปรียบเทียบ
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
            { arraySelect.map((item)=>(
              <Card className="p-2 pl-3 mb-2" style={{borderRadius: "unset"}}>
                <div>
                  <div className="text-center" style={{width: "50px", float: "left"}}>
                    <img style={{maxHeight: "35px"}} alt="" src={(item.logo) ? item.logo :  "/images/uclas-logo-default.png"} width="auto"/>
                  </div> 
                  <span className="middle-text">{item.name_th}</span>  
                  <FontAwesomeIcon onClick={() => this.removeUniversity(item)} color="#33489F" icon={faTimes} className="mr-2 mt-2" style={{float: "right", cursor:"pointer"}}/>
                </div> 
              </Card>
            ))
            }
            { arraySelect.length <= 3 ?
            <div>
            <Form className="mt-3" style={{borderRadius: "unset"}}>
              <Form.Group>
                <Typeahead
                id="basic-typeahead-single"
                labelKey="name_th"
                onChange={(selected) => {
                    this.pushArray(selected)
                  }}
                selected ={this.state.selected}
                options={option}
                placeholder="+ เพิ่มข้อมูล"
                renderMenuItemChildren={(option) => (
                  <div style={{height: "53px",display: "table-cell" ,verticalAlign: "middle"}}>
                      <img alt="" src={(option.logo) ? option.logo :  "/images/uclas-logo-default.png"} width="30px" height="auto" className="mr-3"/>
                      {option.name_th}
                    </div>
                  )}
                />
              </Form.Group>
            </Form>
             </div>:<></>
             }
            <div className="text-center mt-3">
                <Button disabled={arraySelect.length <= 1} className="btn-submit mr-2 mb-2" style={{width: "140px", height: "36px"}} size="sm" onClick={this.submit}>เปรียบเทียบ</Button>
                <Button className="btn-cancel mr-2 mb-2" size="sm" style={{width: "140px", height: "36px"}} onClick={this.clear}>ล้าง</Button>
            </div>
        </Modal.Body>
      </Modal>
    );
  }
}

export default ModalCompare;