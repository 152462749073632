import axios from 'axios'; 
import { Config } from '../assets/config';

const fetchOverall = (year) => {
    if(!year){
        year = "";
    }
    return new Promise(async function (resolve, reject) {
        axios.get(`${Config.publicURL}/data-services/overall-university-score/${year}`, {
            headers: {
                'Content-Type': 'application/json',
            }
        }).then(response => {
            resolve(response)
        }).catch(error => {
            reject(error)
        })
    })
}

const universitiesList = (year) => {
    if(!year){
        year = "";
    }
    return new Promise(async function (resolve, reject) {
        axios.get(`${Config.publicURL}/data-services/universities/${year}`, {
            headers: {
                'Content-Type': 'application/json',
            }
        }).then(response => {
            resolve(response)
        }).catch(error => {
            reject(error)
        })
    })
}

const getUniversityDataForPreview = (url) => {
    return new Promise(async function (resolve, reject) {
        axios.post(`${url}`, {
            headers: {
                'Content-Type': 'application/json',
            },
            data:{
                "jsonrpc": 2.0
            }
        }).then(response => {
            resolve(response)
        }).catch(error => {
            reject(error)
        })
    })
}

const universityDetail = (year, university) => {
    return new Promise(async function (resolve, reject) {
        axios.post(`${Config.publicURL}/data-services/universities/detail`, {
            headers: {
                'Content-Type': 'application/json',
            },
            params: {
                year: year,
                university: university
            }
        }).then(response => {
            resolve(response)
        }).catch(error => {
            reject(error)
        })
    })
}


const compareUniversity = (dataByHome) => {
    return new Promise(async function (resolve, reject) {
        axios.post(`${Config.publicURL}/data-services/universities/compare`, {
            headers: {
                'Content-Type': 'application/json',
            },
            params: {
                university: dataByHome.university,
                year: dataByHome.year
            }
        }).then(response => {
            resolve(response)
        }).catch(error => {
            reject(error)
        })
    })
}

const saveCompare = (filter) => {
    return new Promise(async function (resolve, reject) {
        axios.post(`${Config.publicURL}/data-services/data-compare`, {
            headers: {
                'Content-Type': 'application/json',
            },
            params: {
                data: filter
            }
        }).then(response => {
            resolve(response)
        }).catch(error => {
            reject(error)
        })
    })
}

const openCompare = (filter) => {
    return new Promise(async function (resolve, reject) {
        axios.get(`${Config.publicURL}/data-services/data-compare/${filter}`, {
            headers: {
                'Content-Type': 'application/json',
            },
        }).then(response => {
            resolve(response)
        }).catch(error => {
            reject(error)
        })
    })
}

const yearListByUniversity = (university) => {
    return new Promise(async function (resolve, reject) {
        axios.post(`${Config.publicURL}/data-services/years`, {
            headers: {
                'Content-Type': 'application/json',
            },
            params: {
                university: university
            }
        }).then(response => {
            resolve(response)
        }).catch(error => {
            reject(error)
        })
    })
}

const lastYearUpdateBlobData = () => {
    return new Promise(async function (resolve, reject) {
        axios.get(`${Config.publicURL}/data-services/blob/info`, {
            headers: {
                'Content-Type': 'application/json',
            }
        }).then(response => {
            resolve(response)
        }).catch(error => {
            reject(error)
        })
    })
}


const getStrategicIntent = (year) => {
    return new Promise(async function (resolve, reject) {
        axios.get(`${Config.publicURL}/data-services/indicator/strategic-intent/${year}`, {
            headers: {
                'Content-Type': 'application/json',
            }
        }).then(response => {
            resolve(response)
        }).catch(error => {
            reject(error)
        })
    })
}



const httpRequest = { fetchOverall, universitiesList, universityDetail, compareUniversity, saveCompare, openCompare, yearListByUniversity, getUniversityDataForPreview, lastYearUpdateBlobData, getStrategicIntent }
export default httpRequest