const formatterData = (data) => {
    var tmp = [];
    for(var i in data){
      var arrTooltip_en = [];
      var arrTooltip_th = [];
      for(var j in data){
        if(data[i].potential === data[j].potential && data[i].performance === data[j].performance){
          arrTooltip_en.push(data[j].abbreviation_en)
          arrTooltip_th.push(data[j].abbreviation_th)
        }
      }
      var obj = {
        "label_en": "",
        "label_th": "",
        "tooltip_en": arrTooltip_en,
        "tooltip_th": arrTooltip_th,
        "potential": data[i].potential,
        "performance": data[i].performance
      }
      tmp.push(obj);
    }

    var jsonString = tmp.map(JSON.stringify);
    var uniqueSet = new Set(jsonString);
    var uniqueArray = Array.from(uniqueSet).map(JSON.parse); 
    for(var k in uniqueArray){
      if(uniqueArray[k].tooltip_en.length>1){
        uniqueArray[k].label_en = `(${uniqueArray[k].tooltip_en.length})`
        uniqueArray[k].label_th = `(${uniqueArray[k].tooltip_en.length})`
      }
      else{
        uniqueArray[k].label_en = uniqueArray[k].tooltip_en[0]
        uniqueArray[k].label_th = uniqueArray[k].tooltip_th[0]
      }
      uniqueArray[k].tooltip_en = uniqueArray[k].tooltip_en.join(", ")
      uniqueArray[k].tooltip_th = uniqueArray[k].tooltip_th.join(", ")
    }
    return uniqueArray;
}

const convertDataToQuadrant = (data,quadrant_num) => {
  var data_quadrant = JSON.parse(JSON.stringify(data));
  data_quadrant.forEach((data) => {
    if(quadrant_num === 1){
      data.performance = data.performance * -1;
    }
    else if(quadrant_num === 3){
      data.potential = data.potential * -1;
      
    }
    else if(quadrant_num === 4){
      data.potential = data.potential * -1;
      data.performance = data.performance * -1;
    }
  })
  return data_quadrant;
}

const formatData = { formatterData, convertDataToQuadrant }
export default formatData