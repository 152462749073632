import React, { Component } from 'react';
import { Link } from 'react-router-dom';
class Header extends Component {

  render() {
    return (
      <div className="header" style={{borderBottom: "1px solid #E7E7E7", background: "linear-gradient(0deg, #FDFDFD, #FDFDFD)"}}>
      <div className="container mt-3 mb-3">
          <div>       
              <div>
              <Link to="/">              
              <img style={{float:"left", width: "5rem"}}
                alt=""
                src="/images/reinventing-logo.svg"
                height="auto"
                className="mr-3"
              />
              </Link>
              </div> 
              <div style={{paddingTop: "10px"}}> <span> โครงการพัฒนาระบบประเมินตนเองและการจัดกลุ่มสถาบันอุดมศึกษา<br/> เชิงยุทธศาสตร์เพื่อสนับสนุนนโยบายประเทศไทย 4.0</span></div>
          </div>
      </div>
      </div>
    );
  }
}

export default Header;