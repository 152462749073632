import React, { Component } from 'react';

class Watermark extends Component{
    render() {
        return(
            <div className="watermark-container">
                <div className="watermark-description">
                    ข้อมูลการประเมินที่ปรากฏอาจมีการเปลี่ยนแปลงในอนาคต <br></br>โปรดรอการตรวจสอบข้อมูลจาก<br></br>สำนักงานปลัดกระทรวงการอุดมศึกษา วิทยาศาสตร์ วิจัยและนวัตกรรม (สป.อว.)
                </div>
            </div>
        );
    }
}

export default Watermark