import React, { Component } from 'react';
import * as am4core from "@amcharts/amcharts4/core";
import * as am4charts from "@amcharts/amcharts4/charts";
import { isMobile } from "react-device-detect";

class QuadrantAll extends Component {
  constructor(props) {
    super(props);
    this.state = {
      descLabelConfig: {
        "strokeWidth": 0,
        "fontSize": 14,
        "isMeasured": false,
        "horizontalCenter": "middle",
        "zIndex": 1
      },
      bulletConfig: {
        "width": 12,
        "height": 12,
        "stroke": am4core.color("#000000"),
        "strokeWidth": 1,
        "strokeOpacity": 0,
        "fillOpacity": 0.7,
        "tooltipText": `{tooltip_en}`
      },
      seriesConfig: {
        "dataFields" : {
          "valueX" : "performance",
          "valueY" : "potential"
        }
      },
      colorList: ["#FAB319","#314BFD","#C1D050","#E0368C"]
    }
  }
  
  componentDidMount() {
    this.initChart(); 
  }

  componentWillUnmount() {
    if(this.chart) {
      this.chart.dispose();
    }
  }

  initChart(){
    const { abbreviation, type, data } = this.props
    const { descLabelConfig, seriesConfig, bulletConfig, colorList} = this.state;

    var chart = am4core.create(this.props.id, am4charts.XYChart);

    var labelRightTop = chart.createChild(am4core.Label);
    labelRightTop.text = this.props.strategicList[localStorage.getItem('strategic2')];
    labelRightTop.fill = am4core.color("#8D97BE");
    labelRightTop.x = (type === "detail") ? am4core.percent(76): am4core.percent(76);
    labelRightTop.y = (type === "detail") ? am4core.percent(1): am4core.percent(1);
    labelRightTop.config = descLabelConfig;

    var labelLeftTop = chart.createChild(am4core.Label);
    labelLeftTop.text = this.props.strategicList[localStorage.getItem('strategic1')];
    labelLeftTop.fill = am4core.color("#8D97BE");
    labelLeftTop.x = (type === "detail") ? am4core.percent(30): am4core.percent(28);
    labelLeftTop.y = (type === "detail") ? am4core.percent(1): am4core.percent(1);
    labelLeftTop.config = descLabelConfig;

    var labelLeftBot = chart.createChild(am4core.Label);
    labelLeftBot.text = this.props.strategicList[localStorage.getItem('strategic4')];
    labelLeftBot.fill = am4core.color("#8D97BE");
    labelLeftBot.x = (type === "detail") ? am4core.percent(30): am4core.percent(28);
    labelLeftBot.y = (type === "detail") ? am4core.percent(82): am4core.percent(88);
    labelLeftBot.config = descLabelConfig;

    var labelRightBot = chart.createChild(am4core.Label);
    labelRightBot.text =this.props.strategicList[localStorage.getItem('strategic3')];
    labelRightBot.fill = am4core.color("#8D97BE");
    labelRightBot.x = (type === "detail") ? am4core.percent(76): am4core.percent(76);
    labelRightBot.y = (type === "detail") ? am4core.percent(82): am4core.percent(88);
    labelRightBot.config = descLabelConfig;

    // Create axes
    var valueAxisX = chart.xAxes.push(new am4charts.ValueAxis());
    valueAxisX.title.text = "Performance";
    valueAxisX.min = -6;
    valueAxisX.max = 6;
    valueAxisX.strokeWidth = 0;
    valueAxisX.strictMinMax = true;
    valueAxisX.renderer.minGridDistance = 20;

    // Create value axis
    var valueAxisY = chart.yAxes.push(new am4charts.ValueAxis());
    valueAxisY.title.text = "Potential";
    valueAxisY.min = -6;
    valueAxisY.max = 6;
    valueAxisY.strokeWidth = 0;
    valueAxisY.strictMinMax = true;
    valueAxisY.renderer.minGridDistance = 20;

    chart.numberFormatter.numberFormat = "#.#s";

    valueAxisX.renderer.grid.template.disabled = true;
    valueAxisY.renderer.grid.template.disabled = true;

    function createGrid(value) {
      var rangeX = valueAxisX.axisRanges.create();
      var rangeY = valueAxisY.axisRanges.create();
      rangeX.value = value;
      rangeX.endValue = value;
      rangeX.grid.stroke = am4core.color("#DBDBDB");

      rangeY.value = value;
      rangeY.endValue = value;
      rangeY.grid.stroke = am4core.color("#DBDBDB");

      if(value === 0){
        rangeX.grid.strokeWidth = 1;
        rangeY.grid.strokeWidth = 1;
        rangeX.grid.strokeOpacity = 1;
        rangeY.grid.strokeOpacity = 1;
      }
    }
    
    createGrid(-2.5)
    createGrid(0)
    createGrid(2.5)

    valueAxisX.renderer.labels.template.adapter.add("text", function(text, target) {
      if (typeof text !== 'undefined'){
        return text.match(/^(-?[6-7]+\d*)$/g) ? "" : text;
      }
      return text;
    });
    valueAxisY.renderer.labels.template.adapter.add("text", function(text, target) {
      if (typeof text !== 'undefined'){
        return text.match(/^(-?[6-7]+\d*)$/g) ? "" : text;
      }
      return text;
    });
    
    function createSeries(){

      data.forEach((data,index) => {
        // Create series
        var series = chart.series.push(new am4charts.LineSeries());
        series.config = seriesConfig
        series.strokeOpacity = 0;
        series.tooltip.label.wrap = true;

        // Assign label position 
        data.forEach((bullet) => {
          var arrayTooltip = bullet.tooltip_en.split(", ");
          var university_count = arrayTooltip.length;
          bullet.dy = university_count * (-2);
        })
        series.dataFields.customValue = "dy"

        // Wrap tooltip text
        if(isMobile){
          series.tooltip.label.maxWidth = 200;
        }
        else{
          series.tooltip.label.maxWidth = 300;
        }

        // Detail page, Sort data and Remove other university label
        if(type === "detail"){
          data.forEach((bullet,i) => {
            var arrayTooltip = bullet.tooltip_en.split(", ");
            if(arrayTooltip.includes(abbreviation)){
              data.push(data.splice(i, 1)[0]);
            }
          });
          data.forEach((bullet) => {
            var arrayTooltip = bullet.tooltip_en.split(", ");
            if(!arrayTooltip.includes(abbreviation)){
              bullet.label_en = "";
            }
            else{
              bullet.label_en = abbreviation;
            }
          });
        }

        series.data = data;
        // Add bullet
        var bullet = series.bullets.push(new am4charts.CircleBullet());
        bullet.config = bulletConfig;
        bullet.fill = am4core.color(colorList[index]);

        // Add fill
        bullet.circle.adapter.add("fill", (fill, target) => {
          var arrayTooltip = target.dataItem.dataContext.tooltip_en.split(", ");
          if(arrayTooltip.includes(abbreviation)){
            return am4core.color(colorList[index]);
          }
          else if(type === "detail"){
            return am4core.color("#E1E2E8");
          }
          return fill;
        });
        
        // Edit radius
        bullet.circle.adapter.add("radius", (radius, target) => {
          var arrayTooltip = target.dataItem.dataContext.tooltip_en.split(", ");
          var width = 5;
          width =  width + (arrayTooltip.length*2);
          return width;
        });

        // Add label bullet
        var labelBullet = series.bullets.push(new am4charts.LabelBullet());
        labelBullet.label.text = `{label_en}`
        labelBullet.fontSize = 12;
        labelBullet.zIndex = -20;
        labelBullet.label.adapter.add("dy", (dy, target) => {
          return target.dataItem.values.customValue.value - 12
        })

        // Add hover bullet
        var hoverState = bullet.states.create("hover");
        hoverState.properties.fillOpacity = 1;
        hoverState.properties.strokeOpacity = 1;
      });
    }
    
    createSeries();
    chart.stroke = am4core.color("#DBDBDB");
    chart.strokeWidth = 1;

    // Enable to zoom
    chart.cursor = new am4charts.XYCursor();
    chart.cursor.behavior = "zoomXY";
    chart.cursor.lineY.disabled = true;
    chart.cursor.lineX.disabled = true;
    valueAxisX.cursorTooltipEnabled = false;
    valueAxisY.cursorTooltipEnabled = false;

    // Responsive
    chart.responsive.enabled = true;
    if (this.props.type === "detail"){
      chart.responsive.rules.push({
        relevant: function(target) {
          if (target.pixelWidth <= 600) {
            return true;
          }
          return false;
        },
        state: function(target, stateId) {
          var state = target.states.create(stateId);
          if (target instanceof am4charts.XYChart) {
            for(var i=0; i<4; i++){
              var topicLabel = target.children.values[i+1].states.create(stateId);
              topicLabel.properties.fontSize = 8;
              topicLabel.properties.truncate = true;
              topicLabel.properties.maxWidth = 120;
            }
          }
          if(target instanceof am4charts.CircleBullet){
            var initialLabel = target.children.values[0].states.create(stateId);
            initialLabel.properties.fontSize = 8;
          }
          return state;
        }
      });
      chart.responsive.rules.push({
        relevant: function(target) {
          if (target.pixelWidth <= 320) {
            return true;
          }
          return false;
        },
        state: function(target, stateId) {
          if (target instanceof am4charts.XYChart) {
            for(var i=0; i<4; i++){
              var topicLabel = target.children.values[i+1].states.create(stateId);
              topicLabel.properties.fontSize = 7;
            }
          }
        }
      });
    }
    else{
      chart.responsive.rules.push({
        relevant: function(target) {
          if (target.pixelWidth <= 600) {
            return true;
          }
          return false;
        },
        state: function(target, stateId) {
          var state = target.states.create(stateId);
          if (target instanceof am4charts.XYChart) {
            for(var i=0; i<4; i++){
              var topicLabel = target.children.values[i+1].states.create(stateId);
              topicLabel.properties.fontSize = 8;
              topicLabel.properties.truncate = true;
              topicLabel.properties.maxWidth = 150;
            }
          }
          if(target instanceof am4charts.CircleBullet){
            var initialLabel = target.children.values[0].states.create(stateId);
            initialLabel.properties.fontSize = 8;
          }
          if(target instanceof am4charts.ValueAxis){
            target.fontSize = 10;
          }
          return state;
        }
      });
      
      chart.responsive.rules.push({
        relevant: function(target) {
          if (target.pixelWidth <= 225) {
            return true;
          }
          return false;
        },
        state: function(target, stateId) {
          if (target instanceof am4charts.XYChart) {
            for(var i=0; i<4; i++){
              var topicLabel = target.children.values[i+1].states.create(stateId);
              topicLabel.properties.disabled = true;
            }
          }
        }
      });
    }

    this.chart = chart;
  }

  render() {
    return (
      <div className="chart" id={this.props.id} style={{ width: this.props.width, height: this.props.height, margin: "auto" }}></div>
    );
  }
}

export default QuadrantAll;