import React, { Component } from 'react';
import { Form, Modal } from 'react-bootstrap';

class ModalSaveCompare extends Component {
  constructor(props) {
    super(props);
    this.state = {
    }
  }

  copyCodeToClipboard = () => {
    const el = this.textArea
    el.select()
    document.execCommand("copy")
  }

  render() {
    return (
        <Modal style={{borderRadius: "unset"}}
        {...this.props}
        size="lg"
        dialogClassName="modal-save-compare"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Header closeButton className="p-3">
          <Modal.Title id="contained-modal-title-vcenter">
            บันทึกการเปรียบเทียบ
          </Modal.Title>
        </Modal.Header>
        <Modal.Body style={{height: "30rem"}}>
            <div className="p-2 pl-3" style={{background: "#F1F1F1"}}>
              { this.props.saveData.universityData ? 
                <>
                  {  this.props.saveData.universityData.map((item)=>(
                    <div className="p-2" style={{height:"50px"}}><img alt="" src={(item.profile.logo) ? item.profile.logo : "/images/uclas-logo-default.png"} width="25px" height="auto" className="middle-text mr-3"/> <small className="middle-text">{item.profile.university_th}</small> </div> 
                  ))
                  }
                </> : <></>
              }
            </div> 
            <div className="mt-3">
                <img src="/images/share.svg" alt="" className="mr-1"/>ลิงก์สำหรับแสดงข้อมูลการเปรียบเทียบนี้ 
                <small onClick={() => this.copyCodeToClipboard()} style={{float: "right", padding: "3px 10px", cursor:"pointer"}}><img src="/images/copy.svg" alt="" className="mr-1" style={{width: "15px"}}/>คัดลอก</small> 
            </div>
            <div className="mt-2 p-2 pl-3" style={{background: "#F1F1F1"}}>
                <Form.Control ref={(textarea) => this.textArea = textarea} style={{fontSize: "0.8rem", borderRadius: 0}} type="text" value={`${window.location.origin}/compare/${this.props.saveData.link}`}  placeholder="" />
            </div>
        </Modal.Body>
      </Modal>
    );
  }
}

export default ModalSaveCompare;