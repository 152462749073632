import React, { Component } from 'react';
import { Container, InputGroup } from 'react-bootstrap';
import Search from '../component/Search';
import Loading from '../component/Loading';
import ModalCompare from '../component/ModalCompare';
import httpRequest from '../assets/request-api';
import { withRouter } from "react-router-dom";
import ModalNotFound from '../component/ModalNotFound';;

class Notfound extends Component {
    constructor(props) {
        super(props);
        this.toProfilePage = this.toProfilePage.bind(this);
        this.state = {
            modalNotFound: false,
            isLoading: true,
            modalShow: false,
            optionSearch: [],
            selectedYear: null,
        }
    }
    
    componentDidMount(){
      localStorage.clear();
      this.getLastestYearFromBlobStorage().then(() => {
        this.universityList().then(() => {
          this.setState({isLoading: false})
        })
      })
    }

    async getLastestYearFromBlobStorage() {
      httpRequest.lastYearUpdateBlobData().then(response => {
        const lastestYear = response.data.last_year_update;
        this.setState({
          selectedYear: lastestYear
        });
      }).catch(() => {
        this.setState({ modalNotFound: true, isLoading: false})
      })
    }

    async universityList() {
      await httpRequest.universitiesList(this.state.selectedYear).then(res => {  
        this.setState({ optionSearch: res.data })
      }).catch(() => {
        this.setState({ modalNotFound: true, isLoading: false})
      })
    }

    async toProfilePage(path) {
        if(path.length !== 0 ){
          var list_of_years;
          var data_current_year
          await httpRequest.yearListByUniversity(path[0].slug).then(res => {
            list_of_years = res.data.list_of_years;
            data_current_year = list_of_years.length !== 0 ? Math.max(...list_of_years) : " "
          }).catch(err => {
            this.setState({ modalNotFound: true})
          })
          window.location.assign(`${window.location.origin}/detail/${path[0].slug}/${data_current_year}`)
        }
    }

    toHomePage() {
      this.props.history.push(`/`)
    }

    setModalShow(boo){
        this.setState({
            modalShow : boo
        })
    }

  render() {
    const { optionSearch, isLoading } = this.state;
    if (isLoading) {
        return <div className="loading-page">
          <Loading/>
          </div>;
      }
    return (
    <div className="bg-404">
      <Container className="text-center">
          <h1 className="p-3 color-white">404</h1>
          <h3 className="p-2 color-white">ไม่พบข้อมูลในหน้านี้</h3>
          <h5 className="p-2 color-white">ต้องการค้นหา หรือสนใจข้อมูลอื่น ๆ หรือไม่ ?</h5>
            <InputGroup size="sm" className="mt-1" style={{justifyContent: "center"}}>
              <Search placeholder={"ค้นหา"} option={optionSearch} width={"260px"} size={"sm"} selectedPage={this.toProfilePage}/>
            </InputGroup>
          <div role="button" className="btn mr-2 color-white" onClick={() => this.toHomePage()}>
            <img alt="Home Icon" src="/images/home-white.svg" width="19px" height="auto" className="mr-1"/>หน้าแรก
          </div>
          <div role="button" className="btn mr-2 color-white" onClick={() => this.setModalShow(true)}>
            <img alt="Compare Icon" src="/images/compare-icon-white.svg" width="16px" height="auto" className="mr-1"/>เปรียบเทียบ
          </div>
      </Container>
      <ModalCompare
      history={this.props.history}
      modelData={this.state.optionSearch}
      show={this.state.modalShow}
      onHide={() => this.setModalShow(false)}
      />
      <ModalNotFound show={this.state.modalNotFound}/>
    </div>
    );
  }
}

export default withRouter(Notfound);