import React, { Component } from 'react';
import { Button, Col, Container, Navbar } from 'react-bootstrap'
import { Cookies } from "react-cookie-consent";

class Cookie extends Component {
    constructor(props) {
        super(props);
        this.state = {
            cookieName: "uclasCookieConsent",
            showBanner: true
        }
      }

    setCookie(bool){
        Cookies.set(this.state.cookieName,bool,{expires: 365});
        this.setState({ showBanner: false});
    }
    render(){
        const {cookieName, showBanner} = this.state;
        let isActivateCookie = Cookies.get(cookieName);
        let cookieBanner;
        if (!isActivateCookie){
            cookieBanner = <Navbar fixed="bottom" className="cookieNavbar" style={{display: showBanner ? 'block' : 'none'}}>
            <Container>
                <Col lg={9}>
                ข้อมูลที่เกี่ยวข้องกับการเข้าสู่เว็ปไซต์ของท่านจะถูกเก็บเอาไว้ในรูปแบบของคุกกี้<br/>ซึ่งเมื่อท่านเข้าสู่เว็บไซต์นี้นั่นหมายถึงว่าท่านได้อนุญาตให้เราใช้คุกกี้ เพื่อให้แน่ใจว่าคุณจะได้รับประสบการณ์ที่ดีที่สุดบนเว็บไซต์ของเรา
                </Col>
                <Col lg={3}>
                <div className="float-right"><Button className="cookieButton" onClick={() => this.setCookie(true)}>ตกลง</Button></div>
                </Col>
            </Container>
        </Navbar>
        }
        else{
            cookieBanner = <></>
        }
        return(
            <div>
                {cookieBanner}
            </div>
        );
    }
}

export default Cookie;