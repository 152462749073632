import React, { Component } from 'react';
import * as am4core from "@amcharts/amcharts4/core";
import * as am4charts from "@amcharts/amcharts4/charts"; 
import { isMobile } from "react-device-detect";

class QuadrantOne extends Component {
  constructor(props) {
    super(props);
    this.state = {
      descLabelConfig: {
        "strokeWidth": 0,
        "fontSize": 14,
        "isMeasured": false,
        "horizontalCenter": "middle",
        "zIndex": 1
      },
      bulletConfig : {
        "width": 12,
        "height": 12,
        "stroke": am4core.color("#000000"),
        "strokeWidth": 1,
        "strokeOpacity": 0,
        "fillOpacity": 0.7,
        "tooltipText": `{tooltip_en}`
      },
      seriesConfig : {
        "dataFields" : {
          "valueX" : "axis_performance_value",
          "valueY" : "axis_potential_value"
        }
      },
      axisRangesConfig: {
        "value": 3,
        "endValue": 3,
        "grid": {
          "disabled": true,
          "stroke": am4core.color("#DBDBDB"),
          "strokeWidth": 1,
          "strokeOpacity": 1
        }
      }
    }
  }
  
  componentDidMount() {
    this.initChart();
  }

  componentWillUnmount() {
    if (this.chart) {
      this.chart.dispose();
    }
  }

  initChart(){
    const { abbreviation, type, color, data} = this.props
    const { descLabelConfig, seriesConfig, bulletConfig, axisRangesConfig} = this.state

    var chart = am4core.create(this.props.id, am4charts.XYChart);
    chart.responsive.enabled = true;
    chart.stroke = am4core.color("#DBDBDB");

    var labelRightTop = chart.createChild(am4core.Label);
    labelRightTop.text = "High Potential - High Performance";
    labelRightTop.fill = am4core.color("#8D97BE");
    labelRightTop.x = am4core.percent(75);
    labelRightTop.y = am4core.percent(1);
    labelRightTop.config = descLabelConfig;

    var labelLeftTop = chart.createChild(am4core.Label);
    labelLeftTop.text = "High Potential - Low Performance";
    labelLeftTop.fill = am4core.color("#8D97BE");
    labelLeftTop.x = am4core.percent(25);
    labelLeftTop.y = am4core.percent(1);
    labelLeftTop.config = descLabelConfig;

    var labelLeftBot = chart.createChild(am4core.Label);
    labelLeftBot.text = "Low Potential - Low Performance";
    labelLeftBot.fill = am4core.color("#8D97BE");
    labelLeftBot.x = am4core.percent(25);
    labelLeftBot.y = (type === "detail") ? am4core.percent(84): am4core.percent(86);
    labelLeftBot.config = descLabelConfig;

    var labelRightBot = chart.createChild(am4core.Label);
    labelRightBot.text = "Low Potential - High Performance";
    labelRightBot.fill = am4core.color("#8D97BE");
    labelRightBot.x = am4core.percent(75);
    labelRightBot.y = (type === "detail") ? am4core.percent(84): am4core.percent(86);
    labelRightBot.config = descLabelConfig;

    // Create axes
    var valueAxisX = chart.xAxes.push(new am4charts.ValueAxis());
    valueAxisX.title.text = "Performance";
    valueAxisX.min = 0;
    valueAxisX.max = 6;
    valueAxisX.strokeWidth = 0;
    valueAxisX.renderer.minGridDistance = 40;

    // Create value axis
    var valueAxisY = chart.yAxes.push(new am4charts.ValueAxis());
    valueAxisY.title.text = "Potential";
    valueAxisY.min = 0;
    valueAxisY.max = 6;
    valueAxisY.strokeWidth = 0;
    valueAxisY.renderer.minGridDistance = 40;

    valueAxisX.renderer.grid.template.disabled = true;
    valueAxisX.renderer.labels.template.disabled = true;
    valueAxisY.renderer.grid.template.disabled = true;
    valueAxisY.renderer.labels.template.disabled = true;

    var rangeX = valueAxisX.axisRanges.create();
    rangeX.config = axisRangesConfig;

    var rangeY = valueAxisY.axisRanges.create();
    rangeY.config = axisRangesConfig;

    function createSeries(){
      // Create series
      var series = chart.series.push(new am4charts.LineSeries());
      series.config = seriesConfig;
      series.strokeOpacity = 0;
      series.tooltip.label.wrap = true;

      // Assign label position 
      data.forEach((bullet) => {
        var arrayTooltip = bullet.tooltip_en.split(", ");
        var university_count = arrayTooltip.length;
        var potential_score = bullet.potential;
        var performance_score = bullet.performance;
        bullet.dy = university_count * (-2);
        bullet.axis_potential_value = potential_score + 0.5;
        bullet.axis_performance_value = performance_score + 0.5;
      })
      series.dataFields.customValue = "dy"

      // Wrap tooltip text
      if(isMobile){
        series.tooltip.label.maxWidth = 200;
      }
      else{
        series.tooltip.label.maxWidth = 300;
      }

      // Detail page, Sort data and Remove other university label
      if(type === "detail"){
        data.forEach((bullet,i) => {
          var arrayTooltip = bullet.tooltip_en.split(", ")
          if(arrayTooltip.includes(abbreviation)){
            data.push(data.splice(i, 1)[0]);
          }
        })
        data.forEach((bullet) => {
          var arrayTooltip = bullet.tooltip_en.split(", ")
          if(!arrayTooltip.includes(abbreviation)){
            bullet.label_en = "";
          }
          else{
            bullet.label_en = abbreviation;
          }
        });
      }
      
      series.data = data;

      // Add bullet
      var bullet = series.bullets.push(new am4charts.CircleBullet());
      bullet.config = bulletConfig;
      bullet.fill = am4core.color(color);

      // Add fill
      bullet.circle.adapter.add("fill", (fill, target) => {
        var arrayTooltip = target.dataItem.dataContext.tooltip_en.split(", ");
        if(arrayTooltip.includes(abbreviation)){
          return am4core.color(color);;
        }
        else if(type === "detail"){
          return am4core.color("#E1E2E8");
        }
        return fill;
      });
      
      // Edit radius
      bullet.circle.adapter.add("radius", (radius, target) => {
        var arrayTooltip = target.dataItem.dataContext.tooltip_en.split(", ");
        var width = 5;
        width =  width + (arrayTooltip.length*2) 
        return width;
      });

      // Add label to bullet
      var labelBullet = series.bullets.push(new am4charts.LabelBullet());
      labelBullet.label.text = `{label_en}`
      labelBullet.fontSize = 12;
      labelBullet.label.adapter.add("dy", (dy, target) => {
        return target.dataItem.values.customValue.value - 12
      })

      // Add hover bullet
      var hoverState = bullet.states.create("hover");
      hoverState.properties.fillOpacity = 1;
      hoverState.properties.strokeOpacity = 1;
    }

    createSeries();

    // Enable to zoom
    chart.cursor = new am4charts.XYCursor();
    chart.cursor.behavior = "zoomXY";
    chart.cursor.lineY.disabled = true;
    chart.cursor.lineX.disabled = true;
    valueAxisX.cursorTooltipEnabled = false;
    valueAxisY.cursorTooltipEnabled = false;

    // Responsive
    chart.responsive.enabled = true;
    if (this.props.type === "detail"){
      chart.responsive.rules.push({
        relevant: function(target) {
          if (target.pixelWidth <= 500) {
            return true;
          }
          return false;
        },
        state: function(target, stateId) {
          var state = target.states.create(stateId);
          if (target instanceof am4charts.XYChart) {
            for(var i=0; i<4; i++){
              var topicLabel = target.children.values[i+1].states.create(stateId);
              topicLabel.properties.fontSize = 8;
              topicLabel.properties.truncate = true;
              topicLabel.properties.maxWidth = 120;
            }
          }
          if(target instanceof am4charts.CircleBullet){
            var initialLabel = target.children.values[0].states.create(stateId);
            initialLabel.properties.fontSize = 8;
          }
          return state;
        }
      });
      chart.responsive.rules.push({
        relevant: function(target) {
          if (target.pixelWidth <= 320) {
            return true;
          }
          return false;
        },
        state: function(target, stateId) {
          if (target instanceof am4charts.XYChart) {
            for(var i=0; i<4; i++){
              var topicLabel = target.children.values[i+1].states.create(stateId);
              topicLabel.properties.fontSize = 7;
            }
          }
        }
      });
    }
    else{
      chart.responsive.rules.push({
        relevant: function(target) {
          if (target.pixelWidth <= 500) {
            return true;
          }
          return false;
        },
        state: function(target, stateId) {
          var state = target.states.create(stateId);
          if (target instanceof am4charts.XYChart) {
            for(var i=0; i<4; i++){
              var topicLabel = target.children.values[i+1].states.create(stateId);
              topicLabel.properties.fontSize = 8;
              topicLabel.properties.truncate = true;
              topicLabel.properties.maxWidth = 150;
            }
          }
          if(target instanceof am4charts.CircleBullet){
            var initialLabel = target.children.values[0].states.create(stateId);
            initialLabel.properties.fontSize = 8;
          }
          if(target instanceof am4charts.ValueAxis){
            target.fontSize = 10;
          }
          return state;
        }
      });
      chart.responsive.rules.push({
        relevant: function(target) {
          if (target.pixelWidth <= 225) {
            return true;
          }
          return false;
        },
        state: function(target, stateId) {
          if (target instanceof am4charts.XYChart) {
            for(var i=0; i<4; i++){
              var topicLabel = target.children.values[i+1].states.create(stateId);
              topicLabel.properties.disabled = true;
            }
          }
        }
      });
    }

    this.chart = chart;
  }

  render() {
    return (
      <div className="chart" id={this.props.id} style={{ width: this.props.width, height: this.props.height, margin: "auto" }}></div>
    );
  }
}

export default QuadrantOne;
