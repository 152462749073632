import React, { Component } from 'react';
import { Col, Row, Container } from 'react-bootstrap';
import { Link } from 'react-router-dom';

class Footer extends Component {

  render() {
    return (
      <div className="footer">
        <Container className="text-uppercase">
              <Row className="mb-2 mt-2" id="footer-content">  
                  <Col style={{maxWidth: "fit-content"}}>
                  <a href="https://reinventing.mhesi.go.th/" target="_blank" rel="noopener noreferrer">
                      <img
                        className="mr-1"
                        src="/images/language-icon.svg"
                        width="18px"
                        height="auto"
                        alt=""
                      />
                      <span style={{color: "#0B0134"}}>Reinventing Website</span>
                  </a>
                  </Col>
                  <Col style={{maxWidth: "fit-content"}}><a href="https://reinventing.mhesi.go.th/uclas/" target="_blank" rel="noopener noreferrer">About us</a></Col>
                  <Col style={{maxWidth: "fit-content", display: "none"}}><Link to="">Terms and conditions</Link></Col>
                  <Col style={{maxWidth: "fit-content", display: "none"}}><Link to="">Privacy policy</Link></Col>
              </Row>
        </Container>
      </div>
    );
  }
}

export default Footer;