import React from "react";
import Footer from "./component/Footer";
import Header from "./component/Header";
import Detail from "./page/Detail";
import Compare from "./page/Compare";
import Home from "./page/Home";
import Notfound from "./page/Notfound";
import "bootstrap/dist/css/bootstrap.min.css";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import "./App.scss";
import { faCircle, faPlus, faTimes, faSpinner} from '@fortawesome/free-solid-svg-icons'
import { library } from '@fortawesome/fontawesome-svg-core'
import Cookie from "./component/Cookie";
library.add( faCircle, faPlus, faTimes, faSpinner)
function App() {
  return (
    <div className="App">
      <Router>
        <Header />
        <Switch>
          <Route path="/detail/:university/:year" component={Detail}/>
          <Route path="/preview/:data" component={Detail}/>
          <Route path="/compare/:base/:compare" component={Compare}/>
          <Route exact path="/" component={Home}/>
          <Route path='*' component={Notfound} />
        </Switch>
        <Footer />
      </Router>
      <Cookie />
    </div>
  );
}

export default App;
