import React, { Component} from 'react';
import { Media, ToggleButtonGroup, ToggleButton, Col, Row, Container, Button, Card, Dropdown, DropdownButton, Spinner, ProgressBar, Alert} from 'react-bootstrap';
import RadarTimeline from '../component/RadarTimeline';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import 'bootstrap/dist/css/bootstrap.css'; // or include from a CDN
import Search from '../component/Search';
import httpRequest from '../assets/request-api'
import ModalSaveCompare from '../component/ModalSaveCompare.jsx';
import domtoimage from 'dom-to-image';
import Loading from '../component/Loading';
import { Link, Element } from 'react-scroll'
import { Link as LinkAs } from 'react-router-dom';
import CsvDownload from 'react-json-to-csv';
import { withNamespaces } from 'react-i18next';
import Notfound from '../page/Notfound';
import ModalNotFound from '../component/ModalNotFound';

class Compare extends Component {
    constructor(props) {
        super(props);
        this.selectUniversity = this.selectUniversity.bind(this);
        this.setToggleIndicator = this.setToggleIndicator.bind(this);
        this.state = {
            isDownloading: false,
            isLoading: true,
            loadSearch: false,
            dataCompare: [],
            selectedYear: null,
            search: [],
            notFoundPage: false,
            modalSaveCompareShow: false,
            modalNotFoundShow: false,
            universityData: [],
            toggleIndicator: true,
            kpiScore: [],
            saveData: {},
            show: {boo: false, message: ""},
            titleData : [
                {
                    colorTheme: "#FAB319"
                },
                {
                    colorTheme: "#314BFD"
                },
                {
                    colorTheme: "#C1D050"
                },         
                {
                    colorTheme: "#E0368C"
                },
                {
                    colorTheme: "#545863"
                },
                {
                    colorTheme: "#63326E"
                },
                {
                    colorTheme: "#861388"
                },
                {
                    colorTheme: "#BBB193"
                },
            ],
            sunburstYear: "",
            year: "",
            yearOption: [],
            yearProfileList: [],
            optionUniversitySearch:[],
            strategic_id: [],
            strategicIntent: []
        }
      }

    async componentDidMount(){
        try{
            if(this.props.match.params.compare === "true"){
                const dataCompare =  await JSON.parse(atob(this.props.match.params.base))
                const selectedYear = await dataCompare.year;
                await this.setState({
                    selectedYear: selectedYear,
                    dataCompare: dataCompare
                });
                this.initData();
            }
            else{
                const dataCompare = await this.props.match.params.base;
                await this.openSaveCompare(dataCompare);
                this.initData();
            }
        }
        catch(error){
            this.setState({
                notFoundPage: true,
                isLoading: false
            })
        }
    }

    async openSaveCompare(data) {
        await httpRequest.openCompare(data).then(response => {
            const dataCompare = response.data;
            this.setState({
                dataCompare: dataCompare
            });
        })
    }

    initData(){
        this.renderDetailForCompare().then(()=> { 
            this.getConcatYearList().then(() => {
                this.setScoreKpiList().then(() => {
                    if(this.state.dataCompare.filter.length !== 0){
                        this.state.dataCompare.filter.forEach((item)=>{
                            this.toggleFilter(item, false)
                        })
                    }
                    this.getUniversitiesList().then(() => {
                        this.setState({isLoading: false})
                    })
                })
            })
        })
    }

    async renderDetailForCompare() {
        try{
            this.setState({
                isLoading: true
            })
            const yearProfileList = await this.getYearProfile(this.state.dataCompare);
            const dataCompare = await httpRequest.compareUniversity(this.state.dataCompare);
            const strategicIntent = await this.getStrategicIntent(this.state.dataCompare);
            var strategicList = [];
            var universityList = dataCompare.data;
            const itemAlls = this.state.search.filter( item => item.slug !== this.state.dataCompare.select);
            universityList[0].strategic_intent.forEach(strategic => {
                strategicList.push(strategic.strategic_id)
            })
            universityList.forEach((university,i)=> {
                yearProfileList.forEach((yearProfile,j)=> {
                    if(university.profile.slug === yearProfile.slug){
                        university.currentYear = yearProfile.currentYear;
                        university.yearList = yearProfile.yearList;
                    }
                })
            })
            this.setState({
                strategicIntent: strategicIntent,
                strategic_id: strategicList,
                dataCompare: {year: this.state.dataCompare.year, university:this.state.dataCompare.university, filter: this.state.dataCompare.filter},
                selectedYear: this.state.dataCompare.year,
                sunburstYear: this.state.dataCompare.year,
                search: itemAlls,
                universityData: universityList,
                yearProfileList : yearProfileList
            });
        }
        catch(error){
            this.setState({
                modalNotFoundShow: true,
                isLoading: false
            })
        }
    }

    async getYearProfile(dataCompare) {
        try{
            var yearProfileList = [];
            dataCompare.university.forEach(async(university) => {
                const response =  await httpRequest.yearListByUniversity(university);
                var listOfYear = response.data.list_of_years;
                var currentYearProfile;
                if(listOfYear !==0){
                    currentYearProfile = Math.max(...listOfYear);
                    yearProfileList.push({"slug": university, "currentYear": currentYearProfile, "yearList": listOfYear});
                }
            })
            return yearProfileList;
        }
        catch(error){
            this.setState({
                isLoading: false,
                modalNotFoundShow: true
            })
        }
    }

    getStrategicIntent = async(dataCompare) => {
        const strategicList = await httpRequest.getStrategicIntent(dataCompare.year);
        var strategicIntent = [];
        await strategicList.data.forEach(strategic => {
          strategicIntent.push(strategic.name_en)
        })
        return strategicIntent
      }
    
    getConcatYearList = async() => {
        var concatYear = [];
        this.state.yearProfileList.forEach((profile,index) => {
            concatYear = concatYear.concat(profile.yearList);
        })
        var yearOption = await this.getDuplicateArrayElements(concatYear);
        this.setState({yearOption: yearOption})
    }

    getDuplicateArrayElements(yearArray){
        var findDuplicates = arr => arr.filter((item, index) => arr.indexOf(item) !== index)
        var result =  [...new Set(findDuplicates(yearArray))]
        return result;
    }

    closeUniversitySelect = async(dataClose) =>{
        const data  = this.state.dataCompare.university.filter(item => item !== dataClose.profile.slug )
        const filterList = await this.getFilterList();
        await this.setState({dataCompare: {year: this.state.selectedYear, university:data, filter: filterList}});
        this.initData();
    }

    getFilterList = async() => {
        var filterList = [];
        await this.state.kpiScore[0].forEach((strategic,index) => {
            if(strategic.filter === false){
                filterList.push(index)
            }
        });
        return filterList
    }

    selectUniversity = async(select) => {
        const add = await this.state.dataCompare.university.concat(select[0].slug);
        const filterList = await this.getFilterList();
        await this.setState({dataCompare : {year: this.state.selectedYear, 
            university: add,
            select: select[0].slug,
            filter: filterList}
        });
        this.initData();
    }

    selectyear = async () =>{
        const filterList = await this.getFilterList();
        await this.setState({dataCompare : {year: this.state.selectedYear, university:this.state.dataCompare.university, filter: filterList}});
        this.initData({});
    }

    getStrategicforRender = (universityData) =>{
        var dataRender0 = [];
        var dataRender1  = [];
        var dataRender2 = [];
        var dataRender3 = [];
        var dataRender4 = [];
        var dataRender5 = [];
        var dataRender6 = [];
        var dataRender7 = [];
        var dataRenders = [];
        dataRenders.push(dataRender0);
        dataRenders.push(dataRender1);
        dataRenders.push(dataRender2);
        dataRenders.push(dataRender3);
        dataRenders.push(dataRender4);
        dataRenders.push(dataRender5);
        dataRenders.push(dataRender6);
        dataRenders.push(dataRender7);
        universityData[0].strategic_intent.forEach((strategic,index) => {
            var title = strategic.name_en;
            if(title=== undefined){
                title = ""
            }
            for(var j=0; j<8;j++){
                dataRenders[j].push({"data": [],"filter": true,"title": title})
            }
        })
        return dataRenders
        
    }

    setScoreKpiList = async ()=>{
        var dataRender = this.getStrategicforRender(this.state.universityData)
        const universityData = this.state.universityData;
        const strategicList = this.state.strategic_id;

        universityData.forEach((university,index) => {
            university.kpi_evaluation.forEach((kpi)=>{
                if(strategicList.indexOf(kpi.strategic_id) === 0){
                    dataRender[index][0].data.push(kpi)
                }
                else if (strategicList.indexOf(kpi.strategic_id) === 1){
                    dataRender[index][1].data.push(kpi)
                }
                else if (strategicList.indexOf(kpi.strategic_id) === 2){
                    dataRender[index][2].data.push(kpi)
                }
                else if (strategicList.indexOf(kpi.strategic_id) === 3){
                    dataRender[index][3].data.push(kpi)
                }
                else if (strategicList.indexOf(kpi.strategic_id) === 4){
                    dataRender[index][4].data.push(kpi)
                }
                else if (strategicList.indexOf(kpi.strategic_id) === 5){
                    dataRender[index][5].data.push(kpi)
                }
                else if (strategicList.indexOf(kpi.strategic_id) === 6){
                    dataRender[index][6].data.push(kpi)
                }
                else if (strategicList.indexOf(kpi.strategic_id) === 7){
                    dataRender[index][7].data.push(kpi)
                }
            })
        })
        this.setState({
            kpiScore: dataRender
        })
    }

    getUniversitiesList = async () =>{
        this.setState({
            loadSearch: true
        })
        await httpRequest.universitiesList(this.state.selectedYear).then(res => {
            if(typeof res !== "undefined"){
                const itemAlls  = res.data.filter( item => {
                    return !this.state.dataCompare.university.find( cartItem => cartItem === item.slug );
                });
                this.setState({
                    optionUniversitySearch : res.data,
                    search: itemAlls,
                    loadSearch: false
                })
            }
        }).catch((e)=> {
            this.setState({
                loadSearch: false,
                modalNotFoundShow: true
            })
        })
    }

    setModalShow = async(boo) =>{
        var filterSave = [] 
        await this.state.kpiScore[0].forEach((data, index) =>{
            if(data.filter === false){
                filterSave.push(index)
            }
        })
        this.saveSubmit(boo, {year: this.state.selectedYear, university: this.state.dataCompare.university, filter: filterSave})
    }
      
    handleSelectYear(eventKey, event) {
        if(!eventKey){
            eventKey = 0
        }
        this.setState({selectedYear: this.state.yearOption[eventKey]}); 
        
      }

    toggleFilter = async(index, boo) =>{
        await this.setState(prevState => {
            let filter = [ ...prevState.kpiScore ];
            filter.forEach((strategic,i)=> {
                strategic[index].filter = boo
            })
            filter.filter(item => item.filter === true)
            return { kpiScore: filter};                               
          })
    }

    setToggleIndicator = (boo) =>{
        this.setState({
            toggleIndicator : boo
        })
    }

    performanceSetdata = (kpi, strategic, type, index) => {
      let radarData = []
      let detail = []
      kpi.forEach((item)=>{
        if(item.strategic_id === strategic && item.type === type){
            radarData.push(item)
            detail.push({name_th: item.name_th, name_en: item.name_en})
        }
      })

      return radarData;
    }

    saveSubmit = async (boo, dataCompare) =>{
        await httpRequest.saveCompare(dataCompare).then(res => {
            if(typeof res !== "undefined"){
                this.setState({
                    saveData: {link: `${res.data.file_name}/save`, universityData: this.state.universityData },
                    modalSaveCompareShow : boo
                })
            }
        }).catch((e)=> {
            this.setShowAlert({boo: true , message: e.message})
            this.setState({
                modalNotFoundShow: true
            })
        })
    }
    
    printDocument() {
        this.setState({
            isDownloading: true
        })
        setTimeout(() => {
            domtoimage.toJpeg(document.getElementById('div-image-export'), { quality: 0.95 })
            .then(function (dataUrl) {
                    var link = document.createElement('a');
                    link.download = 'compare-universiter.jpeg';
                    link.href = dataUrl;
                    link.click(); 
            });
            this.setState({
                isDownloading: false
            })
        }, 1000);
    }

    exportCSV = ((universityData) => {
        var formatExport = []
        universityData.forEach((item)=>{
              formatExport.push({university_en: item.profile.university_en, university_th: item.profile.university_th})  
            item.kpi_evaluation.forEach((kpi)=>{
                formatExport.push({strategic_en: item.strategic_intent[this.state.strategic_id.indexOf(kpi.strategic_id)].name_en, strategic_th: item.strategic_intent[this.state.strategic_id.indexOf(kpi.strategic_id)].name_th, type_en:kpi.type_en, type_th:kpi.type_th, name_en:kpi.name_en, name_th:kpi.name_th, score:kpi.score})
            })
        })
        return formatExport
    })

    setShowAlert(show){
        this.setState({
            show: show,
        })
        setTimeout(() => {
            this.setState({
                show : false
            })
        }, 3000);
    }

    toProfilePage = async(path) => {
        if(path.length !== 0 ){
          var list_of_years;
          var data_current_year;
          await httpRequest.yearListByUniversity(path[0].slug).then(res => {
            list_of_years = res.data.list_of_years;
            data_current_year = list_of_years.length !== 0 ? Math.max(...list_of_years) : " "
          }).catch(err => {
            this.setState({ modalNotFoundShow: true})
          })
          window.location.assign(`${window.location.origin}/detail/${path[0].slug}/${data_current_year}`)
        }
    }

    render() {
        const { strategicIntent, optionUniversitySearch, sunburstYear, yearOption, isLoading, show, titleData, search, isDownloading, loadSearch, universityData, kpiScore, dataCompare, strategic_id } = this.state
        if (isLoading) {
            return <div className="loading-page">
              <Loading/>
            </div>;
        }

        if (this.state.notFoundPage) {
            return <Notfound/>
        }
    return (
    <div className="div-compare" id="div-image-export">
    { kpiScore[0] ?
        <Container>
            { !show.boo?<></>: 
            <Alert key={1} variant="danger" onClose={() => this.setShowAlert({boo: false, message:""})} dismissible>
                <Alert.Heading>ตรวพบข้อผิดพลาด !</Alert.Heading>
                <p>
                   กรุณาลองใหม่อีกครั้ง
                </p>
            </Alert>
            }
            <Row className="mb-3">
                <Col sm={12} md={6}>
                    <nav aria-label="breadcrumb">
                        <ol className="breadcrumb" style={{background: "linear-gradient(0deg, #FDFDFD, #FDFDFD)", margin: 0, paddingLeft: "0px"}}>
                            <li className="breadcrumb-item content-text breadcrumb-link"><LinkAs to="/">หน้าแรก</LinkAs></li>
                            <li className="breadcrumb-item content-text active" aria-current="page">เปรียบเทียบ</li>
                        </ol>
                    </nav>
                </Col>
                <Col sm={12} md={6}>
                    <Row className="pt-2 float-right">
                        <Search placeholder={"ค้นหา"} option={optionUniversitySearch} width="180px" size={"sm"} selectedPage={this.toProfilePage}/> 
                        <a className="ml-2" href="https://reinventing.mhesi.go.th/knowledge-base/%e0%b8%81%e0%b8%b2%e0%b8%a3%e0%b9%83%e0%b8%8a%e0%b9%89%e0%b8%87%e0%b8%b2%e0%b8%99%e0%b8%81%e0%b8%b2%e0%b8%99%e0%b9%80%e0%b8%9b%e0%b8%a3%e0%b8%b5%e0%b8%a2%e0%b8%9a%e0%b9%80%e0%b8%97%e0%b8%b5%e0%b8%a2/" target="_blank" rel="noopener noreferrer">
                            <Button>
                                <img alt="Book Icon" src="/images/book-solid.svg" width="14px" height="auto" className="mr-1"/>คลังความรู้
                            </Button>
                        </a>
                    </Row>
                </Col>
            </Row>
            <Row>
                <Col>
                    <Alert variant="danger" className="text-center m-0" style={{width: "100%"}}>
                    <h3 className="m-0">ห้ามนำไปอ้างอิงเนื่องจากข้อมูลการจัดกลุ่มและการประเมินยังไม่ได้รับการยืนยัน</h3>
                    </Alert>
                </Col>
            </Row>
            <Row className="mt-3">
                
                <Col className="align-self-center">
                    <h5>รายการเปรียบเทียบ</h5> 
                </Col>
                <Col>
                    <div className="text-right">
                    {isDownloading? <FontAwesomeIcon icon="spinner" className="mr-3" spin/>: <></>}
                    <CsvDownload className="btn-primary" filename={`uclas-export.csv`} data={this.exportCSV(universityData)}><img src="/images/download-solid.svg" alt="" width="12px" height="auto" className="mr-2"/><small>CSV</small></CsvDownload>
                    <Button size="sm" disabled={isDownloading} onClick={() => this.printDocument()}><img src="/images/download-solid.svg" alt="" width="12px" height="auto" className="mr-2"/><small>Image</small></Button>
                    <Button className="btn-submit" size="sm" style={{width: "80px"}} onClick={() => this.setModalShow(true)}>บันทึก</Button> {' '}
                    </div>
                </Col>
            </Row>
            <hr/>
            <Row>
                <Col style={{maxWidth: "fit-content"}}>
                    <img alt="" src={"/images/filter-icon.svg"} width="20px" height="auto"/>
                </Col>
                <Col>
                {kpiScore[0].map((item, index) =>{
                    return (
                    !item.filter ?
                    <Link key={index} className={`scroll${index+1}`} activeClass="active" to={`scroll${index+1}`} spy={true} smooth={true} duration={500} >
                        <Button className="btn-filter-strategic-active mr-2" variant="outline-*" onClick={() => this.toggleFilter(index, true)}>{item.title}</Button>
                    </Link>
                    :
                    <Button key={index} className="btn-filter-strategic-deactive mr-2" variant="outline-*" onClick={() => this.toggleFilter(index, false)}>{item.title}</Button>
                    )})
                }
                </Col>
            </Row>
            <hr/>
            <Row className="mt-3">
                <Col>
                    <ToggleButtonGroup className="float-right w-75" name="sunburstToggleOptions" type="radio" value={this.state.toggleIndicator} onChange={this.setToggleIndicator}>
                        <ToggleButton className="btn-indicator-type" value={true} style={ this.state.toggleIndicator ? {borderColor: "#000000", color: "#000"}:{} }  onClick={this.setToggleIndicator}>Potential indicators</ToggleButton>
                        <ToggleButton className="btn-indicator-type" value={false} style={ this.state.toggleIndicator ? {} : {borderColor: "#000000", color: "#000" }} onClick={this.setToggleIndicator}>Performance indicators</ToggleButton>
                    </ToggleButtonGroup>
                </Col>
            </Row>
            <Row className="mt-3">
                <Col>
                    <Row className="mb-3">
                        <Col>
                            <Card className="card-compare p-3">
                            <div>
                                <h6>แสดงข้อมูลของปี</h6>
                                    <DropdownButton style={{fontSize: "0rem"}}
                                        className="dropdown-card-select-year"
                                        size="sm"
                                        title={this.state.selectedYear}
                                        id="document-type"
                                        onSelect={this.handleSelectYear.bind(this)}
                                    >
                                        {yearOption.map((opt, i) => (
                                        <Dropdown.Item key={i} eventKey={i} className="text-center">
                                            {opt}
                                        </Dropdown.Item>
                                        ))}
                                    </DropdownButton>
                                <Button className="btn-submit mt-2" size="sm" style={{width: "100%"}} onClick={() => this.selectyear()}>แสดง</Button> {' '}  
                            </div>
                            </Card>
                        </Col>
                    </Row>
                    <Row className="mb-3">
                        <Col>
                            <div style={{height: "60px"}}>
                                <p className="mb-0"><FontAwesomeIcon className="mr-2" icon="circle" size="xs" color="#41AEB7"/><small className="mr-2">Potential</small></p>
                                <p className="mb-0"><FontAwesomeIcon className="mr-2" icon="circle" size="xs" color="#0A568B"/><small className="mr-2">Performance</small></p>
                            </div>
                        </Col>
                    </Row>
                    { kpiScore[0].map((item,index) => (
                    <div key={index}>
                    { item.filter ? 
                        <>
                            <Row>
                                <Col className="height-strategic pt-3" style={{ color: titleData[index].colorTheme }}>
                                    <h6 style={{position: "absolute", width: "max-content"}}>{item.title}</h6>
                                </Col>
                            </Row>
                            { item.data.map((itemHeader,index) => (
                            <Row key={index}>
                                    <Col className="height-title">
                                        <Media>
                                            <FontAwesomeIcon className="mr-2" style={{alignSelf:"start"}} icon="circle" size="xs" color={itemHeader.type_en === "Performance" ? "#0A568B": "#41AEB7" } />    
                                            <Media.Body>
                                                <h6 style={{fontSize: "12px"}}>{itemHeader.name_th}</h6>
                                            </Media.Body>
                                        </Media>
                                    </Col>
                            </Row>
                            ))}
                        </>: <></>
                    }
                    </div>
                    ))
                    }
                </Col>
                { universityData.map((item, index) => {
                    return(
                <Col key={index}>
                    <Element name="scroll1" className="element" >
                    <div>
                        <Row className="mb-3">
                                <Col className="text-center">
                                    <Card className="card-compare">
                                    { this.state.toggleIndicator ?
                                        <RadarTimeline key={`chart-compare-potential-${index}`} id={`chart-compare-potential-${index}`} data={item.kpi_data_potential} year={sunburstYear} strategicNameList={strategicIntent} strategicIdList={strategic_id} width= "100%" height="300px"/>
                                        :
                                        <RadarTimeline key={`chart-compare-performance-${index}`} id={`chart-compare-performance-${index}`} data={item.kpi_data_performance} year={sunburstYear} strategicNameList={strategicIntent} strategicIdList={strategic_id} width= "100%" height="300px"/>
                                    }
                                    </Card>
                                </Col>
                        </Row>
                        <Row className="mb-3" style={{height: "60px"}}>
                            <Col>
                                <Media>
                                    <img width={32} heigth={32} className="mr-3" alt="University Logo" src={(item.profile.logo) ? item.profile.logo : "/images/uclas-logo-default.png"} style={{alignSelf: "center"}}/>
                                    <Media.Body>
                                     <a href={`/detail/${item.profile.slug}/${item.currentYear}`} style={{fontSize: "12px", alignSelf: "center"}}>{item.profile.university_th}</a>
                                    </Media.Body>
                                    { universityData.length !== 1 ?
                                    <img width={20} height={20} alt="Close University" src={"/images/close-icon.svg"} style={{cursor: "pointer",alignSelf: "center"}} onClick={() => this.closeUniversitySelect(item)}/>
                                    :<></>
                                    }
                                </Media>
                            </Col>
                        </Row>
                        { kpiScore[index][0].filter ?
                            <>
                                <Row>
                                    <Col className="height-strategic">
                                    </Col>
                                </Row>
                            {  kpiScore[index][0].data.map((res,index)=>(
                                <Row key={index.toString()}>
                                    <Col className="height-title">
                                        <ProgressBar variant={`progressbar-index-${strategic_id.indexOf(res.strategic_id)}-score-${res.score}`} style={{color: "rgb(250, 179, 25)"}} now={res.score* 20} />
                                        <div className="text-center mt-07"><small>{res.score}</small></div>
                                    </Col>
                                </Row>
                            ))
                            }
                            </> : <></>
                        }
                    </div>    
                    </Element>
                    <Element name="scroll2" className="element">
                    <div>
                        { kpiScore[index][1].filter ?
                            <>
                                <Row>
                                        <Col className="height-strategic">
                                        </Col>
                                </Row>
                            {  kpiScore[index][1].data.map((res, index)=>(
                                    <Row key={index.toString()}>
                                            <Col className="height-title">
                                                <ProgressBar variant={`progressbar-index-${strategic_id.indexOf(res.strategic_id)}-score-${res.score}`} style={{color: "rgb(250, 179, 25)"}} now={res.score* 20} />
                                                <div className="text-center mt-07"><small>{res.score}</small></div> 
                                            </Col>
                                    </Row>
                            ))
                            }
                            </>: <></>
                        }
                    </div>
                    </Element>
                    <Element name="scroll3" className="element">
                    <div>
                        { kpiScore[index][2].filter ?
                            <>
                                <Row>
                                    <Col className="height-strategic">
                                    </Col>
                                </Row>
                            {  kpiScore[index][2].data.map((res, index)=>(
                                <Row key={index.toString()}>
                                    <Col className="height-title">
                                        <ProgressBar variant={`progressbar-index-${strategic_id.indexOf(res.strategic_id)}-score-${res.score}`} style={{color: "rgb(250, 179, 25)"}} now={res.score* 20} />
                                        <div className="text-center mt-07"><small>{res.score}</small></div>
                                    </Col>
                                </Row>
                                    ))
                            }
                            </>: <></>
                        }
                    </div>
                    </Element>
                    <Element name="scroll4" className="element" >
                    <div>
                        { kpiScore[index][3].filter ?
                            <>
                                <Row>
                                        <Col className="height-strategic">
                                        </Col>
                                </Row>
                            {  kpiScore[index][3].data.map((res, index)=>(
                                <Row key={index.toString()}>
                                    <Col className="height-title">
                                        <ProgressBar variant={`progressbar-index-${strategic_id.indexOf(res.strategic_id)}-score-${res.score}`} style={{color: "rgb(250, 179, 25)"}} now={res.score* 20} />
                                        <div className="text-center mt-07"><small>{res.score}</small></div>
                                    </Col>
                                </Row>
                            ))
                            }
                            </>: <></>
                        }
                    </div>
                    </Element>
                </Col>
                )})
                }
                { dataCompare.university.length < 4 ?
                <Col>
                    <Row className="mb-3">
                            <Col className="text-left">
                                <Card className="card-compare setInputAddCompare p-2">
                                <h6>เพิ่มมหาวิทยาลัย</h6>{
                                    !loadSearch ?  <Search id="add_university_input" option={search} isAddUniversity={true} placeholder={"+ เพิ่มข้อมูล"} size={"md"} width={"auto"} selectedPage={this.selectUniversity}/>: 
                                    <div className="App" style={{textAlign: "center", marginTop: "20px", marginBottom: "20px"}}>
                                        <Spinner animation="grow" size="sm" className="mr-2" style={{color: "#FAB319"}} role="status"/>
                                        <Spinner animation="grow" size="sm" className="mr-2" style={{color: "#314BFD"}} role="status"/>
                                        <Spinner animation="grow" size="sm" className="mr-2" style={{color: "#C1D050"}} role="status"/>
                                        <Spinner animation="grow" size="sm" className="mr-2" style={{color: "#E0368C"}} role="status"/>
                                    </div>
                                }
                                </Card>
                            </Col>
                    </Row>
                </Col>:<></>
                }
            </Row>
        </Container> 
        : 
        <></>
    }
        <ModalSaveCompare
            saveData = {this.state.saveData}
            show={this.state.modalSaveCompareShow}
            onHide={() => this.setModalShow(false)}
        />
        <ModalNotFound  show={this.state.modalNotFoundShowShow}/>
        
    </div>
    );
  }
}

export default withNamespaces()(Compare);