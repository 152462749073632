import React, { Component } from 'react';
import { Form, InputGroup } from 'react-bootstrap';
import { Typeahead } from 'react-bootstrap-typeahead';

class Search extends Component {
    constructor(props) {
        super(props);
        this.state = {
            option: props.option
        }
      }

    onSelect(selected){
        this.props.selectedPage(selected)
    }

  render() {
      const {option} = this.state
      const { placeholder, isAddUniversity } = this.props
    return (
      <div>
        <Form.Group className="mb-0">
          <InputGroup>
            <InputGroup.Prepend style={{display: isAddUniversity ? "none": ""}}>
              <InputGroup.Text className="input-search-icon">
                <img className="img-search-icon" alt="" src={`/images/search-icon.svg`}/>
              </InputGroup.Text>
            </InputGroup.Prepend>
            <Typeahead 
            id="search"
            className="input-search"
            labelKey="name_th"
            onChange={(selected) => {
                this.onSelect(selected)
              }}
            selected ={this.state.selected}
            options={option}
            placeholder={placeholder}
            renderMenuItemChildren={(option) => (
                <div className="setSearch" style={{display: "table-cell",verticalAlign: "middle"}}>
                  <img alt="" src={(option.logo) ? option.logo : "/images/uclas-logo-default.png"} style={{width:"20px"}} height="auto" className="mr-1"/>
                  <small>{option.name_th}</small>
                </div>
              )}
            />
          </InputGroup>
        </Form.Group>
      </div>
    );
  }
}

export default Search;