import React,{ Component } from 'react';
import { Button, Alert, Container, Col, DropdownButton, Dropdown, OverlayTrigger, Row, ToggleButtonGroup, Tooltip, ToggleButton} from 'react-bootstrap';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import YearCard from '../component/YearCard';
import ColumnChart from '../component/ColumnChart';
import Search from '../component/Search';
import Loading from '../component/Loading';
import ModalCompare from '../component/ModalCompare';
import httpRequest from '../assets/request-api';
import formatData from '../assets/formatter-data';
import QuardrantAll from '../component/QuadrantAll';
import QuardrantOne from '../component/QuadrantOne';
import PieChart from '../component/PieChart';
import AlertInfomation from '../component/AlertInfomation';
import ModalNotFound from '../component/ModalNotFound';
import * as am4core from "@amcharts/amcharts4/core";

const colorList = ["#FAB319", "#314BFD", "#C1D050", "#E0368C", "#2B4141", "#4E0110", "#9FFFF5", "#AA9ABA"];
const columnChartDescription = "การประเมินคะแนนของสถาบันอุดมศึกษาทั้งหมดในประเทศไทยจะแบ่งตามกลุ่มเชิงยุทธศาสตร์ โดยเกณฑ์ตัวชี้วัดจะพิจารณาจากการวัดผล 2 ด้านคือ ด้าน Performance (สีเข้ม) และ ด้าน Potential (สีอ่อน) ตามรายการสถาบันอุดมศึกษา ผู้ใช้งานสามารถจัดเรียงการแสดงผลได้ตามตัวเลือกด้านขวามือ"
const quadrantChartDescription = "แผนภูมิแสดงผลการประเมินตามกลุ่มเชิงยุทธศาสตร์เพื่อแสดงให้เห็นถึงการพัฒนาของสถาบันอุดมศึกษาในด้านต่าง ๆ โดยกลางแผนภูมิจะแสดงถึงจุดเริ่มต้นของกลุ่มเชิงยุทธศาสตร์ต่าง ๆ และการกระจายตัวออกไปในมุมตรงข้ามแสดงถึงผลการประเมินที่ดีขึ้นตามกลุ่มเชิงยุทธศาสตร์"
const pieChartDescription = "แผนภูมิแสดงความโดดเด่นของสถาบันอุดมศึกษาตามจุดมุ่งเน้นเชิงยุทธศาสตร์ของประเทศไทย"

class Home extends Component {
  constructor(props) {
    super(props);
    this.toProfilePage = this.toProfilePage.bind(this);
    this.setToggleQuadrant = this.setToggleQuadrant.bind(this);
    this.state = {
      isLoading: true,
      listOfYearFromBlobStorage: [],
      lastestYearFromBlobStorage: '',
      selectedYear: '',
      strategicNameList: [],
      strategicIdList: [],
      optionSearchUniversity: [],
      optionSortStrategic: [],
      selectedStrategicOptionColumnChart: "ค่าเริ่มต้น",
      modalCompareUniversityShow: false,
      modalSelectStrategicShow: false,
      modalNotFound: false,
      potentialScoreList: [],
      performanceScoreList: [],
      columnChartData: [],
      overViewQuadrant: [],
      quadrantChartData: [],
      pieChartData: [],
      allStrategicScore: '',
      universityScore: '',
      showColumnChart: true,
      toggleQuadrant: 0,
      sortedStrategicList: [],
      sortedStrategicIndexList: [],
      sortedColorList: []
    };
  }

  componentDidMount(){
    this.initData();
  }

  initData() {
    this.getLastestYearFromBlobStorage().then(() => {
      this.getStrategicIntent().then(() => {
        this.getUniversityList().then(() => {
          this.getOverallScore().then(() => {
            this.getColumnChartData().then(() => {
              this.getQuadrantChartData().then(() => {
                this.getPieChartData().then(() => {
                  this.setState({isLoading: false});
                })
              })
            });
          })
        })
      })
    });
  }

  async getLastestYearFromBlobStorage() {
    await httpRequest.lastYearUpdateBlobData().then(response => {
      const lastestYear = response.data.last_year_update;
      const listOfYear = response.data.list_of_year;
      if(localStorage.getItem('selectedYear') === null){
        localStorage.setItem('selectedYear', lastestYear)
      }
      this.setState({
        lastestYearFromBlobStorage: lastestYear,
        listOfYearFromBlobStorage: listOfYear,
        selectedYear: localStorage.getItem('selectedYear')
      })
    }).catch(() => {
      this.setState({
        modalNotFound: true,
        isLoading: false
      })
    });
  }

  async getStrategicIntent(){
    var strategicNameList = [];
    var strategicIdList = [];
    var optionSortStrategic = [];
    await httpRequest.getStrategicIntent(localStorage.getItem('selectedYear')).then((response) => {
      response.data.forEach(strategic => {
        strategicNameList.push(strategic.name_en);
        strategicIdList.push(strategic.strategic_id);
      })
      if(localStorage.getItem('strategicLength') === null || localStorage.getItem('strategicLength') !== strategicNameList.length.toString()){
        localStorage.setItem('strategicLength', strategicNameList.length.toString());
        localStorage.setItem('strategic1', 0);
        localStorage.setItem('strategic2', 1);
        localStorage.setItem('strategic3', 2);
        localStorage.setItem('strategic4', 3);
      }
      for(var i = 0;i<4;i++){
        optionSortStrategic.push(strategicNameList[localStorage.getItem(`strategic${i+1}`)])
      }
      optionSortStrategic.unshift("ค่าเริ่มต้น");
      this.setState({
        strategicNameList: strategicNameList,
        strategicIdList: strategicIdList,
        optionSortStrategic: optionSortStrategic
      })
    }).catch(() => {
      this.setState({
        modalNotFound: true,
        isLoading: false
      })
    });
  }

  async getUniversityList() {
    await httpRequest.universitiesList(localStorage.getItem('selectedYear')).then(response => {
      const universityList = response.data;
      this.setState({
        optionSearchUniversity: universityList
      })
    }).catch(() => {
      this.setState({
        modalNotFound: true,
        isLoading: false
      })
    });
  }

  async getColumnChartData() {
    var columnChartData = [];
    var quadrant1 = [];
    var quadrant2 = [];
    var quadrant3 = [];
    var quadrant4 = [];
    const universityScore = await this.state.universityScore;
    for(const university of universityScore){
      const potentialScoreList = await this.getIndicatorScoreList(university.kpis.Potential);
      const performanceScoreList = await this.getIndicatorScoreList(university.kpis.Performance);
      await columnChartData.push({
        "abbreviation_en": university.name.abbreviation_en,
        "abbreviation_th": university.name.abbreviation_th,
        "university_th": university.name.name_th,
        "university_en": university.name.name_en,
        "url": `${window.location.href}detail/${university.name.slug}/${this.state.selectedYear}`,
        "total_score": university.total_score,
        "potential1": potentialScoreList[localStorage.getItem('strategic1')],
        "performance1": performanceScoreList[localStorage.getItem('strategic1')],
        "potential2": potentialScoreList[localStorage.getItem('strategic2')],
        "performance2": performanceScoreList[localStorage.getItem('strategic2')],
        "potential3": potentialScoreList[localStorage.getItem('strategic3')],
        "performance3": performanceScoreList[localStorage.getItem('strategic3')],
        "potential4": potentialScoreList[localStorage.getItem('strategic4')],
        "performance4": performanceScoreList[localStorage.getItem('strategic4')]
      })
      await quadrant1.push({"abbreviation_th": university.name.abbreviation_th,
      "abbreviation_en": university.name.abbreviation_en,
      "potential": potentialScoreList[localStorage.getItem('strategic1')],
      "performance": performanceScoreList[localStorage.getItem('strategic1')],
      })
      await quadrant2.push({"abbreviation_th": university.name.abbreviation_th,
      "abbreviation_en": university.name.abbreviation_en,
      "potential": potentialScoreList[localStorage.getItem('strategic2')],
      "performance": performanceScoreList[localStorage.getItem('strategic2')],
      })
      await quadrant3.push({"abbreviation_th": university.name.abbreviation_th,
      "abbreviation_en": university.name.abbreviation_en,
      "potential": potentialScoreList[localStorage.getItem('strategic3')],
      "performance": performanceScoreList[localStorage.getItem('strategic3')],
      })
      await quadrant4.push({"abbreviation_th": university.name.abbreviation_th,
      "abbreviation_en": university.name.abbreviation_en,
      "potential": potentialScoreList[localStorage.getItem('strategic4')],
      "performance": performanceScoreList[localStorage.getItem('strategic4')],
      })
    }
    this.setState({
      overViewQuadrant: [
        formatData.convertDataToQuadrant(formatData.formatterData(quadrant1),1),
        formatData.convertDataToQuadrant(formatData.formatterData(quadrant2),2),
        formatData.convertDataToQuadrant(formatData.formatterData(quadrant3),3),
        formatData.convertDataToQuadrant(formatData.formatterData(quadrant4),4)
      ],
      columnChartData: columnChartData
    })
  }

  async getQuadrantChartData() {
    const strategicIdList = await this.state.strategicIdList;
    const strategicNameList = await this.state.strategicNameList;
    const universityScore = await this.state.universityScore;
    var quadrantChartData = await this.getQuadrantListSize(strategicIdList);
    for(const university of universityScore){
      const potentialScoreList = await this.getIndicatorScoreList(university.kpis.Potential);
      const performanceScoreList = await this.getIndicatorScoreList(university.kpis.Performance);
      potentialScoreList.forEach((potential,index) => {
        quadrantChartData[index].push({
          "abbreviation_th": university.name.abbreviation_th,
          "abbreviation_en": university.name.abbreviation_en,
          "potential": potentialScoreList[index],
          "performance": performanceScoreList[index]
        })
      });
    }
    var strategicSelected = [];
    var sortedStrategicIndexList = [];
    var sortedColorList = [];
      [...Array(4)].forEach((strategic,index) => {
        strategicSelected.push(strategicNameList[localStorage.getItem(`strategic${index+1}`)])
        sortedStrategicIndexList.push(localStorage.getItem(`strategic${index+1}`))
        sortedColorList.push(colorList[localStorage.getItem(`strategic${index+1}`)])
      })
    var sortedStrategicList = [...strategicSelected];
    strategicNameList.forEach((strategic,index) => {
      if(!strategicSelected.includes(strategic)){
        sortedStrategicList.push(strategic);
        sortedStrategicIndexList.push(index.toString());
        sortedColorList.push(colorList[index])
      }
    })
    this.setState({
      quadrantChartData: quadrantChartData,
      sortedStrategicList: sortedStrategicList,
      sortedStrategicIndexList: sortedStrategicIndexList,
      sortedColorList: sortedColorList
    })
  }

  async getQuadrantListSize(strategicIdList){
    var quadrantList = [];
    strategicIdList.forEach(async(id,index) => {
      quadrantList[index] = await [];
    })
    return quadrantList;
  }

  async getPieChartData() {
    const strategicList = this.state.strategicNameList;
    const allStrategicScore = this.state.allStrategicScore;
    var pieChartData = [];
    allStrategicScore.forEach((strategic, index) => {
      if(strategic.strategic_id === undefined){
        pieChartData.push({"name": "สถาบันอุดมศึกษาที่ไม่อยู่ในกลุ่มยุทธศาสตร์", "value": parseFloat(strategic.score), "color": am4core.color("#DBDBDB")})
      }
      else{
        pieChartData.push({"name": strategicList[index], "value": parseFloat(strategic.score), "color": am4core.color(this.state.sortedColorList[index])})
      }
    });
    this.setState({
      pieChartData : pieChartData
    })
  }

  async sortColumnChartData(eventKey) {
    var keySort = "";
    if(eventKey === null){
      eventKey = 0
    }
    this.setState({
      selectedStrategicOptionColumnChart: this.state.optionSortStrategic[eventKey] === 'ค่าเริ่มต้น' ? "เรียงตามตัวอักษร (ก - ฮ)" : this.state.optionSortStrategic[eventKey],
      showColumnChart: false
    })
    if(eventKey > 0 & eventKey <5){
        var performanceKey = `performance${eventKey}`
        var potentialKey = `potential${eventKey}`
        keySort = [performanceKey, potentialKey]
      }
    else{
      keySort = ["abbreviation_th", ""]
    }
    const columnChartData = await this.sortFunction(this.state.columnChartData, keySort);
    this.setState({
      columnChartData: columnChartData,
      showColumnChart: true
    })
  }

  async sortFunction(data, keyToSort) {
    var dataToHorizontallyStacked = [];
      [].concat(data)
    .sort((a, b) => a[keyToSort[0]]+a[keyToSort[1]] > b[keyToSort[0]]+b[keyToSort[1]] ? -1 : 1)
    .forEach((item, i) => {
      dataToHorizontallyStacked.push(item)
    })
    return await dataToHorizontallyStacked
  }

  async getIndicatorScoreList(indicatorData){
    var indicatorScore = []
    await indicatorData.forEach(async(indicator) => {
      await indicatorScore.push(indicator.score)
    })
    return indicatorScore
  }

  async getOverallScore() {
    const response = await httpRequest.fetchOverall(localStorage.getItem('selectedYear'));
    const allStrategicScore = await response.data.overall_strategic_score;
    const universityScore = await response.data.university_score;
    this.setState({
      allStrategicScore: allStrategicScore,
      universityScore: universityScore
    })
  }

  async toProfilePage(university) {
    const universitySlug = university[0].slug;
    const response  = await httpRequest.yearListByUniversity(universitySlug);
    var yearList = await response.data.list_of_years;
    var lastestYear = await Math.max(...yearList)
    localStorage.clear();
    window.location.assign(`${window.location.origin}/detail/${universitySlug}/${lastestYear}`)
  }

  setModalCompareUniversityShow(boo){
    this.setState({
      modalCompareUniversityShow : boo
    });
  }

  setModalSelectStrategicShow(boo){
    this.setState({
      modalSelectStrategicShow : boo
    })
  }

  setToggleQuadrant(index){
    this.setState({
      toggleQuadrant: index
    })
  }

  render() {
    const tooltipQuadrant = (props) => (
      <Tooltip id="tooltip-img" {...props}>
        <div className="pt-3 pb-3">
          <Container>
            <Row>
              <img src="/images/quadrant-guideline.gif" alt="" style={{width: "100%", height: "100%"}}/>
            </Row>
          </Container>
        </div>
      </Tooltip>
    );

    if (this.state.isLoading) {
      return <div className="loading-page">
        <Loading/>
      </div>;
    }
    return (
    <div>
      <Container className="pt-2 pb-5">
        <Row className="float-right">
          <Search placeholder={"ค้นหา"} option={this.state.optionSearchUniversity} width="180px" size={"sm"} selectedPage={this.toProfilePage}/>
          <Button className="ml-2 mr-2" onClick={() => this.setModalCompareUniversityShow(true)}>
            <img alt="Compare Icon" src="/images/compare-icon.svg" width="16px" height="auto" className="mr-1"/>เปรียบเทียบ
          </Button>
          <a href="https://reinventing.mhesi.go.th/knowledge-base/%e0%b8%81%e0%b8%b2%e0%b8%a3%e0%b9%83%e0%b8%8a%e0%b9%89%e0%b8%87%e0%b8%b2%e0%b8%99%e0%b8%ab%e0%b8%99%e0%b9%89%e0%b8%b2%e0%b8%a0%e0%b8%b2%e0%b8%9e%e0%b8%a3%e0%b8%a7%e0%b8%a1%e0%b8%81%e0%b8%b2%e0%b8%a3/" 
          target="_blank" rel="noopener noreferrer">
            <Button>
              <img alt="Book Icon" src="/images/book-solid.svg" width="14px" height="auto" className="mr-1"/>คลังความรู้
            </Button>
          </a>
        </Row>
      </Container>

      <div className="banner-info pt-5 pb-5">
        <Container>
          <Row>
            <Col sm={12} md={5}>
              <img src="/images/quadrant-desc.svg" width="100%" alt="Quadrant Description"/>
            </Col>
            <Col sm={12} md={7}>
              <h5 className="text-white">ภาพรวมของการประเมินและการจัดกลุ่มของมหาวิทยาลัย</h5> 
              <p className="text-white">จุดเน้นเชิงยุทธศาสตร์ 4 กลุ่ม<br></br>แสดงถึงความสามารถ และศักยภาพของสถาบันอุดมศึกษาในแต่ละด้าน</p>
              <Row>
              {[...Array(4)].map((x, index) =>
                  <Col sm={12} md={6} key={index}>
                  <FontAwesomeIcon className="mr-2" icon="circle" size="xs" color={colorList[index]}/>
                  <span className="text-white">{this.state.strategicNameList[localStorage.getItem(`strategic${index+1}`)]}</span>
                  </Col>
              )}
              </Row>
              <p className="mt-4 text-white">พิจารณาจากการวัดผล 2 ด้านคือ</p>
              <Row>
                <Col>
                  <Row>
                    <Col sm={12} md={5}><img alt="" src="/images/potential-desc.svg" width="100%" className="mr-2"/></Col>
                    <Col sm={12} md={7}><span className="text-white">ด้านศักยภาพของสถาบันอุดมศึกษาแต่ละกลุ่ม</span></Col>
                  </Row>
                </Col>
                <Col>
                  <Row>
                    <Col sm={12} md={5}><img alt="" src="/images/performance-desc.svg" width="100%" className="mr-2"/></Col>
                    <Col sm={12} md={7}><span className="text-white">ด้านเชิงปฏิบัติการครอบคลุมผลการดําเนินงาน</span></Col>
                  </Row>
                </Col>
              </Row>
            </Col>
          </Row>
          <p className="mb-0 mt-5 text-white">ระดับคะแนนจากตัวชี้วัดแสดงถึงระดับความพร้อมในแต่ละด้านของสถาบันอุดมศึกษา</p>
          <Row className="mb-1 mt-3">
              <Col sm={6} md={3}><img alt="" src="/images/hpolper.svg" width="100%"/></Col>
              <Col sm={6} md={3}><img alt="" src="/images/hpohper.svg" width="100%"/></Col>
              <Col sm={6} md={3}><img alt="" src="/images/lpolper.svg" width="100%"/></Col>
              <Col sm={6} md={3}><img alt="" src="/images/lpohper.svg" width="100%"/></Col>
          </Row>
        </Container>
      </div>
      <Container className="mt-3">
        <Row>
          <Col>
              <Alert variant="danger" className="text-center m-0" style={{width: "100%"}}>
              <h3 className="m-0">ห้ามนำไปอ้างอิงเนื่องจากข้อมูลการจัดกลุ่มและการประเมินยังไม่ได้รับการยืนยัน</h3>
              </Alert>
          </Col>
        </Row>
        <Row className="mt-3">
          <Col>
              <h5>ผลการประเมิน</h5>
              ผลการประเมินคะแนนของสถาบันอุดมศึกษาทั้งหมดในประเทศไทย
          </Col>
          <Col>
            <div className="text-right"><span className="mr-2">Performance indicators</span> <FontAwesomeIcon className="mr-2" icon="circle" size="xs" color="#FAB319" /> <FontAwesomeIcon className="mr-2" icon="circle" size="xs" color="#314BFD" /> <FontAwesomeIcon className="mr-2" icon="circle" size="xs" color="#C1D050" /> <FontAwesomeIcon className="mr-2" icon="circle" size="xs" color="#E0368C" /></div> 
            <div className="text-right"><span className="mr-2">Potential indicator</span> <FontAwesomeIcon className="mr-2" icon="circle" size="xs" color="#F9DDA3" /> <FontAwesomeIcon className="mr-2" icon="circle" size="xs" color="#9EABFF" /> <FontAwesomeIcon className="mr-2" icon="circle" size="xs" color="#D7E282" /> <FontAwesomeIcon className="mr-2" icon="circle" size="xs" color="#DDADC5" /></div> 
          </Col>
        </Row>
        <Row>
          <Col>
            <AlertInfomation type="info" message={columnChartDescription}/>
          </Col>
        </Row>
        <hr/>
        <Row>
          <Col className="text-right">
            <span className="mr-3">เรียงลำดับโดย</span>
            <DropdownButton className="dropdown-column-chart" size="sm" title={this.state.selectedStrategicOptionColumnChart}
             onSelect={this.sortColumnChartData.bind(this)}
            >
              {this.state.optionSortStrategic.map((option,index) => {
                return (
                  <Dropdown.Item key={index} eventKey={index}>
                    {option === "ค่าเริ่มต้น" ? "เรียงลำดับตัวอักษร (ก - ฮ)" : option+" (น้อย - มาก)"}
                  </Dropdown.Item>
                );
              })}
            </DropdownButton>
          </Col>
        </Row>
        <Row>
        </Row>
        <Row>
          {this.state.showColumnChart ? 
          <ColumnChart strategicList={this.state.strategicNameList} horizontallyData={this.state.columnChartData} language={"th"} id="columnChart" width= "100%"  height="600px"/>
          :
          <></>
        }
        </Row>
        <Row>
          <Col>
            <h5>
              ภาพรวมสถาบันอุดมศึกษาตามกลุ่มยุทธศาสตร์
              <OverlayTrigger placement="auto" delay={{ show: 250, hide: 400 }} overlay={tooltipQuadrant}>
                <img className="ml-2 mb-1" src="/images/QuestionMark.svg" alt="Question Mark Icon"/>
              </OverlayTrigger>
            </h5>
            <AlertInfomation type="info" message={quadrantChartDescription}/>
          </Col>
        </Row>
        <hr/>
        <Row>
          <Col>
          <ToggleButtonGroup className="home-quadrant-chart-btn-group" value={this.state.toggleQuadrant} onChange={this.setToggleQuadrant} size="sm" name="quadrant-menu-options" type="radio">
            <ToggleButton style={{pointerEvents: this.state.toggleQuadrant === 0 ? "none" : ""}} onClick={this.setToggleQuadrant} value={0}>
              <FontAwesomeIcon className="mr-2" icon="circle" size="xs"/>
              Overview
            </ToggleButton>
            {
              this.state.sortedStrategicList.map((strategic,index) => {
                return (
                  <ToggleButton key={index.toString()} style={{pointerEvents: this.state.toggleQuadrant === index+1 ? "none" : ""}} onClick={this.setToggleQuadrant} value={index+1}>
                  <FontAwesomeIcon className="mr-2" icon="circle" size="xs" color={colorList[index]}/>
                  {strategic}
                  </ToggleButton>
                );
              })
            }
          </ToggleButtonGroup>
          </Col>
        </Row>
        <Row>
        {this.state.toggleQuadrant === 0 ? 
          <QuardrantAll id="quardrant_chart_all" 
          strategicList={this.state.strategicNameList} 
          data={this.state.overViewQuadrant} width= "90%" height="830px"/> : <></>}
        {
          this.state.quadrantChartData.map((strategic,index) => {
            if(this.state.toggleQuadrant === index+1){
              return ( 
                <QuardrantOne id={`quadrant_chart${index}`} 
                data={formatData.formatterData(this.state.quadrantChartData[this.state.sortedStrategicIndexList[index]])} 
                color={colorList[index]} 
                width= "985px" 
                height="530px"/>
              );
            }
            else{
              return <div key={index.toString()} style={{height: "530px"}}></div>
            }
          })
        }
        </Row>
        <Row>
          <Col>
            <h5>ภาพรวมการจัดกลุ่มสถาบันอุดมศึกษาในประเทศไทย</h5>
            <AlertInfomation type="info" message={pieChartDescription}/>
          </Col>
        </Row>
        <Row>
        </Row>
        <hr/>
        <PieChart id="pie_chart" data={this.state.pieChartData} width= "100%"  height="400px"/>
        {this.state.pieChartData.map((strategic,i) => {
          if(this.state.allStrategicScore[i].member_university.length === 0){
            return <></>
          }
          else{
            return(
              <Row key={i} className="mb-4">
                <Col>
                  <h5 className="text-center">{strategic.name !== "สถาบันอุดมศึกษาที่ไม่อยู่ในกลุ่มยุทธศาสตร์" ? `กลุ่ม ${strategic.name}` : strategic.name }</h5>
                  <hr className="w-50"/>
                  <Row style={{margin: "auto"}}>
                  {
                    this.state.allStrategicScore[i].member_university.map((university,j) => {
                      return(
                        <Col key={j.toString()} sm={4}>
                          <span className="ml-5">• {university.university_th}</span>
                        </Col>
                      );
                    })
                  }
                  </Row>
                </Col>
              </Row>
            );
          }
        })
        }
      </Container>
      <ModalCompare 
        history={this.props.history} 
        modelData={this.state.optionSearchUniversity} 
        year={this.state.selectedYear} 
        show={this.state.modalCompareUniversityShow} 
        onHide={() => this.setModalCompareUniversityShow(false)}/>
      <YearCard 
        strategicList={this.state.strategicNameList}
        selectYear={this.state.selectedYear}
        yearList={this.state.listOfYearFromBlobStorage}/>
      <ModalNotFound show={this.state.modalNotFound}/>
    </div>
    );
  }
}

export default Home;