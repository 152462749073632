import React, { Component } from 'react';
import { Spinner } from 'react-bootstrap';

class Loading extends Component {
    render(){
        return(
            <div>
                <Spinner animation="grow" size="sm" className="mr-2" style={{color: "#FAB319"}} role="status"/>
                <Spinner animation="grow" size="sm" className="mr-2" style={{color: "#314BFD"}} role="status"/>
                <Spinner animation="grow" size="sm" className="mr-2" style={{color: "#E0368C"}} role="status"/>
                <Spinner animation="grow" size="sm" className="mr-2" style={{color: "#C1D050"}} role="status"/>
            </div>
        );
    }
}

export default Loading;