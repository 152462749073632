import React, { Component} from 'react';
import RadarTimeline from '../component/RadarTimeline'
import UniversityDetail from '../component/UniversityDetail';
import MultiCarousel from '../component/Multi-carousel'
import Search from '../component/Search';
import Loading from '../component/Loading';
import ModalCompare from '../component/ModalCompare';
import { Link } from 'react-router-dom';  
import { Alert, Button, Card, Col, Row, Container, OverlayTrigger, Tooltip, ToggleButtonGroup, ToggleButton, ProgressBar } from 'react-bootstrap';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import 'bootstrap/dist/css/bootstrap.css';
import 'react-bootstrap-range-slider/dist/react-bootstrap-range-slider.css';
import httpRequest from '../assets/request-api'
import formatData from '../assets/formatter-data';
import QuardrantAll from '../component/QuadrantAll';
import QuardrantOne from '../component/QuadrantOne';
import ModalNotFound from '../component/ModalNotFound';
import AlertInfomation from '../component/AlertInfomation';
import Watermark from '../component/Watermark';
import YearCard from '../component/YearCard';
import Notfound from '../page/Notfound';

const colorList = ["#FAB319", "#314BFD", "#C1D050", "#E0368C", "#545863", "#63326E", "#861388", "#BBB193"];

class Detail extends Component {
  constructor(props) {
    super(props);
    this.toProfilePage = this.toProfilePage.bind(this);
    this.setToggleQuadrant = this.setToggleQuadrant.bind(this);
    this.setToggleProgressBar = this.setToggleProgressBar.bind(this);
    this.state = {
      isLoading: true,
      notFoundp: false,
      modalCompareShow: false,
      modalNotFoundShow: false,
      optionSearchUniversity: [],
      year: null,
      profile: {},
      kpiDataPotential: {},
      kpiDataPerformance: {},
      kpiEvaluation: {},
      internationalRank: {},
      selectedYear: this.props.match.params.year,
      universityYearList: [],
      viewMode: this.props.match.url.split("/")[1],
      strategicIdList: [],
      strategicIndexList: [],
      strategicNameList: [],
      allStrategicScore: [],
      universityScore: [],
      quadrantChartData: [],
      toggleQuadrant: 0,
      toggleProgressBar: 0,
      sortedStrategicList: [],
      sortedStrategicIndexList: [],
      sortedStrategicIdList: [],
      sortedKpiDataPotential: [],
      sortedKpiDataPerformance: []
  }
}

  componentDidMount(){
    localStorage.setItem('selectedYear', this.state.selectedYear);
    if(this.state.viewMode === "preview"){
      this.previewUniversityDetail().then(() => {
        this.getQuadrantChartData().then(() => {
          this.setState({isLoading: false})
        })
      });
    }
    else{
      this.initData();
    }
  }

  async initData(){
      this.getStrategicIntent().then(() => {
        this.getUniversityProfile().then(() => {
        this.getUniversityList().then(() => {
          this.getOverallScore().then(() => {
            this.getQuadrantChartData().then(() => {
              this.getYearListProfile().then(() => {
                this.setState({isLoading: false})
              })
            })
          })
        })
      })
    })
  }

  async previewUniversityDetail(){
    var decodeString = atob(this.props.match.params.data)
    await httpRequest.getUniversityDataForPreview(decodeString).then(response => {
      const university = response.data.result.detail[0];
      const allStrategicScore = response.data.result.overall_university_score.overall_strategic_score;
      const universityScore = response.data.result.overall_university_score.university_score;
      var strategicNameList = [];
      var strategicIdList = [];
      for(var strategic of response.data.result.indicator.strategic_intent){
        strategicNameList.push(strategic.name_en);
        strategicIdList.push(strategic.strategic_id);
      }
      localStorage.clear();
      localStorage.setItem('selectedYear', university.year)
      localStorage.setItem('strategicLength', strategicNameList.length);
      localStorage.setItem('strategic1', 0);
      localStorage.setItem('strategic2', 1);
      localStorage.setItem('strategic3', 2);
      localStorage.setItem('strategic4', 3);
      this.setState({
        strategicNameList: strategicNameList,
        strategicIdList: strategicIdList,
        year: university.year,
        profile: university.profile,
        internationalRank: university.international_rank,
        kpiDataPotential : university.kpi_data_potential,
        kpiDataPerformance: university.kpi_data_performance,
        kpiEvaluation: university.kpi_evaluation,
        allStrategicScore: allStrategicScore,
        universityScore: universityScore
      })
    }).catch((err)=>{
      this.setState({ modalNotFoundShow: true})
    })
  }

  async getStrategicIntent(){
    var strategicNameList = [];
    var strategicIdList = [];
    await httpRequest.getStrategicIntent(this.state.selectedYear).then((response) => {
      response.data.forEach(strategic => {
        strategicNameList.push(strategic.name_en);
        strategicIdList.push(strategic.strategic_id);
      })
      if(localStorage.getItem('strategicLength') === null || localStorage.getItem('strategicLength') !== strategicNameList.length.toString()){
        localStorage.setItem('strategicLength', strategicNameList.length);
        localStorage.setItem('strategic1', 0);
        localStorage.setItem('strategic2', 1);
        localStorage.setItem('strategic3', 2);
        localStorage.setItem('strategic4', 3);
      }
      this.setState({
        strategicNameList: strategicNameList,
        strategicIdList: strategicIdList
      })
    }).catch(() => {
      this.setState({
        notFoundp: true,
        isLoading: false
      })
    })
  }

  async getOverallScore() {
    await httpRequest.fetchOverall(this.state.selectedYear).then((response) => {
      const allStrategicScore = response.data.overall_strategic_score;
      const universityScore = response.data.university_score;
      this.setState({
        allStrategicScore: allStrategicScore,
        universityScore: universityScore
      })
    }).catch(() => {
      this.setState({
        notFoundp: true,
        isLoading: false
      })
    })
  }

  async getQuadrantChartData(){
    const strategicNameList = await this.state.strategicNameList;
    const strategicIdList = await this.state.strategicIdList;
    const universityScore = await this.state.universityScore;
    var quadrantChartData = await this.getQuadrantListSize(strategicIdList);
    var quadrant1 = [];
    var quadrant2 = [];
    var quadrant3 = [];
    var quadrant4 = [];
    for(const university of universityScore) {
      const potentialScoreList = await this.getIndicatorScoreList(university.kpis.Potential);
      const performanceScoreList = await this.getIndicatorScoreList(university.kpis.Performance);
      potentialScoreList.forEach((potential,index) => {
        quadrantChartData[index].push({
          "abbreviation_th": university.name.abbreviation_th,
          "abbreviation_en": university.name.abbreviation_en,
          "potential": potentialScoreList[index],
          "performance": performanceScoreList[index]
        })
      });
      await quadrant1.push({
        "abbreviation_th": university.name.abbreviation_th,
        "abbreviation_en": university.name.abbreviation_en,
        "potential": potentialScoreList[localStorage.getItem('strategic1')],
        "performance": performanceScoreList[localStorage.getItem('strategic1')],
      });
      await quadrant2.push({
        "abbreviation_th": university.name.abbreviation_th,
        "abbreviation_en": university.name.abbreviation_en,
        "potential": potentialScoreList[localStorage.getItem('strategic2')],
        "performance": performanceScoreList[localStorage.getItem('strategic2')],
      });
      await quadrant3.push({
        "abbreviation_th": university.name.abbreviation_th,
        "abbreviation_en": university.name.abbreviation_en,
        "potential": potentialScoreList[localStorage.getItem('strategic3')],
        "performance": performanceScoreList[localStorage.getItem('strategic3')],
      });
      await quadrant4.push({
        "abbreviation_th": university.name.abbreviation_th,
        "abbreviation_en": university.name.abbreviation_en,
        "potential": potentialScoreList[localStorage.getItem('strategic4')],
        "performance": performanceScoreList[localStorage.getItem('strategic4')],
      });
    }
    var strategicSelected = [];
    var sortedStrategicIndexList = [];
    var sortedStrategicIdList = [];
    var sortedKpiDataPotential = [];
    var sortedKpiDataPerformance = [];
      [...Array(4)].forEach((strategic,index) => {
        strategicSelected.push(strategicNameList[localStorage.getItem(`strategic${index+1}`)])
        sortedStrategicIndexList.push(localStorage.getItem(`strategic${index+1}`))
      })
    var sortedStrategicList = [...strategicSelected];
    strategicNameList.forEach((strategic,index) => {
      if(!strategicSelected.includes(strategic)){
        sortedStrategicList.push(strategic);
        sortedStrategicIndexList.push(index.toString());
      }
    })
    for(var strategic of sortedStrategicIndexList){
      if(this.state.viewMode === 'detail'){
        sortedKpiDataPotential.push(this.state.kpiDataPotential[strategic])
        sortedKpiDataPerformance.push(this.state.kpiDataPerformance[strategic])
        sortedStrategicIdList.push(this.state.strategicIdList[strategic])
      }
      else{
        sortedKpiDataPotential.push(this.state.kpiDataPotential[strategicIdList[strategic]])
        sortedKpiDataPerformance.push(this.state.kpiDataPerformance[strategicIdList[strategic]])
        sortedStrategicIdList.push(this.state.strategicIdList[strategic])
      }
    }
    this.setState({
      overViewQuadrant: [
        formatData.convertDataToQuadrant(formatData.formatterData(quadrant1),1),
        formatData.convertDataToQuadrant(formatData.formatterData(quadrant2),2),
        formatData.convertDataToQuadrant(formatData.formatterData(quadrant3),3),
        formatData.convertDataToQuadrant(formatData.formatterData(quadrant4),4)
      ],
      quadrantChartData: quadrantChartData,
      sortedStrategicList: sortedStrategicList,
      sortedStrategicIndexList: sortedStrategicIndexList,
      sortedStrategicIdList: sortedStrategicIdList,
      sortedKpiDataPotential: sortedKpiDataPotential,
      sortedKpiDataPerformance: sortedKpiDataPerformance,
      toggleProgressBar: sortedStrategicIndexList[0]
    });
  }
  
  async getQuadrantListSize(strategicIdList){
    var quadrantList = [];
    strategicIdList.forEach(async(id,index) => {
      quadrantList[index] = await [];
    })
    return quadrantList;
  }

  async getIndicatorScoreList(indicatorData){
    var indicatorScore = []
    await indicatorData.forEach(async(indicator) => {
      await indicatorScore.push(indicator.score)
    })
    return indicatorScore
  }

  async getYearListProfile(){
    await httpRequest.yearListByUniversity(this.props.match.params.university).then(res => {
      const data = res.data;
      this.setState({ 
        universityYearList: data.list_of_years 
      });
    }).catch(() => {
      this.setState({
        notFoundp: true,
        isLoading: false
      });
    })
  }

  async getUniversityProfile(){
    await httpRequest.universityDetail(this.state.selectedYear,this.props.match.params.university).then(res => {
      const data = res.data[0];
      this.setState({ year: data.year, profile: data.profile, kpiDataPotential: data.kpi_data_potential, kpiDataPerformance: data.kpi_data_performance, internationalRank: data.international_rank, kpiEvaluation: data.kpi_evaluation });
    }).catch(() => {
      this.setState({
        notFoundp: true,
        isLoading: false
      });
    })

  }

  async toProfilePage(path){
    if(path.length !== 0 ){
      var list_of_years;
      var data_current_year;
      await httpRequest.yearListByUniversity(path[0].slug).then(res => {
        list_of_years = res.data.list_of_years;
        data_current_year = list_of_years.length !== 0 ? Math.max(...list_of_years) : " ";
      }).catch(() => {
        this.setState({
          notFoundp: true,
          isLoading: false
        });
      })
      window.location.assign(`${window.location.origin}/detail/${path[0].slug}/${data_current_year}`)
    }
  }

  setToggleQuadrant(index) {
    this.setState({
      toggleQuadrant: index
    });
  }

  setToggleProgressBar(index) {
    this.setState({
      toggleProgressBar: index
    });
  }

  setModalCompareUniversityShow(boo){
    this.setState({
        modalCompareShow : boo
    })
  }

  async getUniversityList(){
    await httpRequest.universitiesList(this.state.selectedYear).then(response => {
      const universityList = response.data;
      this.setState({
        optionSearchUniversity: universityList
      });
    }).catch(() => {
      this.setState({
        notFoundp: true,
        isLoading: false
      });
    })
}

  render(){
    const { modalNotFoundShow, isLoading, year, profile, kpiEvaluation, notFoundp, internationalRank } = this.state;
    const quadrantChartInfo = "แผนภูมิแสดงผลการประเมินตามกลุ่มเชิงยุทธศาสตร์เพื่อแสดงให้เห็นถึงการพัฒนาของสถาบันอุดมศึกษาในด้านต่าง ๆ โดยกลางแผนภูมิจะแสดงถึงจุดเริ่มต้นของกลุ่มเชิงยุทธศาสตร์ต่าง ๆ และการกระจายตัวออกไปในมุมตรงข้ามแสดงถึงผลการประเมินที่ดีขึ้นตามกลุ่มเชิงยุทธศาสตร์"
    const sunburstChartInfo = `ผลการประเมินและการจัดกลุ่มของมหาวิทยาลัย พิจารณาจากการวัดผล 2 ด้าน คือ ด้าน Performance (เชิงปฏิบัติการครอบคลุมผลการดำเนินงาน) และ ด้าน Potential (เชิงศักยภาพของสถาบันอุดมศึกษาแต่ละกลุ่ม) โดยจุดมุ่งเน้นเชิงยุทธศาสตร์ 4 กลุ่มแสดงถึงความสามารถ และศักยภาพของสถาบันอุดมศึกษาในแต่ละด้าน`
    const renderSunburstTooltip = (props) => (
      <Tooltip id="tooltip-img" {...props}>
        <div className="pt-3 pb-3">
          <Container>
            <Row>
              <Col sm={12} md={6} className="mb-3 text-center align-self-center">
                <img src={`/images/sunburst.svg`} alt="" style={{width:"70%"}}/>
              </Col>
              <Col sm={12} md={6}>
                <h5>ผลการประเมินและการจัดกลุ่มของมหาวิทยาลัย</h5> 
                <p>พิจารณาจากการวัดผล 2 ด้านคือ</p>
                <p className="mb-0"><FontAwesomeIcon className="mr-2" icon="circle" size="xs" color="#161148" />ด้านเชิงปฏิบัติการครอบคลุมผลการดําเนินงาน (Performance indicators)</p>
                <p><FontAwesomeIcon className="mr-2" icon="circle" size="xs" color="#161148" /> ด้านศักยภาพ (Potential indicators) ของสถาบันอุดมศึกษาแต่ละกลุ่ม</p>
                <p className="mb-0 mt-4">จุดเน้นเชิงยุทธศาสตร์ 4 กลุ่ม</p>
                <p>แสดงถึงความสามารถ และศักยภาพของสถาบันอุดมศึกษาในแต่ละด้าน</p>
                <p className="mb-1"><img  src={`/images/sunburst_development_innovation.svg`} alt="" style={{width:"40%"}}/> <img  src={`/images/sunburst_area_based.svg`} alt="" style={{width:"40%"}}/></p>
                <p><img  src={`/images/sunburst_global_frontier.svg`} alt="" style={{width:"40%"}}/> <img  src={`/images/sunburst_pro_tech.svg`} alt="" style={{width:"40%"}}/></p>
              </Col>
            </Row>
          </Container>
        </div>
      </Tooltip>
    );
    
    const renderProgressBarTooltip = (message) => (
      <Tooltip className="tooltip-text">
        <div className="p-3">
          {message}
        </div>
      </Tooltip>
    );

    const renderQuadrantGuideTooltip = (props) => (
      <Tooltip id="tooltip-img" {...props}>
        <div className="pt-3 pb-3">
          <Container>
            <Row>
              <img src="/images/quadrant-guideline.gif" alt="" style={{width: "100%", height: "100%"}}/>
            </Row>
          </Container>
        </div>
      </Tooltip>
    );
    
    if (isLoading) {
      return <div className="loading-page">
        <Loading/>
        </div>;
    }

    if (notFoundp) {
      return <Notfound/>
    }
    
    return (
    <div>
      <Container>
        { this.state.viewMode === "preview" ? <Watermark></Watermark> : <></> }
        {/* BreadCrumb & SearchBar */}
        <Row className="mb-3">
          <Col sm={12} md={6}>
          <nav aria-label="breadcrumb">
            <ol className="breadcrumb" style={{background: "linear-gradient(0deg, #FDFDFD, #FDFDFD)", margin: 0, paddingLeft: "0px"}}>
              <li className="breadcrumb-item content-text breadcrumb-link"><Link to="/">หน้าแรก</Link></li>
              <li className="breadcrumb-item content-text active" aria-current="page">{profile.university_th}</li>
            </ol>
          </nav>
          </Col>
          <Col sm={12} md={6}>
            <Row className="pt-2 float-right" style={{display: this.state.viewMode === "preview" ? 'None' : '' }}>
              <Search placeholder={"ค้นหา"} option={this.state.optionSearchUniversity} width="180px" size={"sm"} selectedPage={this.toProfilePage}/>
              <Button className="ml-2 mr-2" onClick={() => this.setModalCompareUniversityShow(true)}>
                <img alt="Compare Icon" src="/images/compare-icon.svg" width="16px" height="auto" className="mr-1"/>เปรียบเทียบ
              </Button>
              <a href="https://reinventing.mhesi.go.th/knowledge-base/%e0%b8%81%e0%b8%b2%e0%b8%a3%e0%b9%83%e0%b8%8a%e0%b9%89%e0%b8%87%e0%b8%b2%e0%b8%99%e0%b8%ab%e0%b8%99%e0%b9%89%e0%b8%b2%e0%b8%81%e0%b8%b2%e0%b8%a3%e0%b8%88%e0%b8%b1%e0%b8%94%e0%b8%81%e0%b8%a5%e0%b8%b8/" 
              target="_blank" rel="noopener noreferrer">
                <Button>
                  <img alt="Book Icon" src="/images/book-solid.svg" width="14px" height="auto" className="mr-1"/>คลังความรู้
                </Button>
              </a>
            </Row>
          </Col>
        </Row>
        {/* Slide Show */}
          { profile.slide_show && profile.slide_show.length !== 0 ?
            <div className="mt-3 mb-3">
              <MultiCarousel images={profile.slide_show}/>
            </div> : <></>
          }
        { this.state.viewMode === "detail" ? 
        <Row>
          <Col>
            <Alert variant="danger" className="text-center m-0" style={{width: "100%"}}>
            <h3 className="m-0">ห้ามนำไปอ้างอิงเนื่องจากข้อมูลการจัดกลุ่มและการประเมินยังไม่ได้รับการยืนยัน</h3>
            </Alert>
          </Col>
        </Row>
        :
        <></>
        }
        <Row>
          {/* University Profile */}
          <Col sm={12} md={4} className="p-0"> 
            <UniversityDetail profile={profile} international_rank={internationalRank}/>
          </Col>
          {/* Quadrant Chart */}
          <Col className="pt-2">
            <Card className="borderless">
              <Card.Header className="borderless" style={{"background": "none"}}>
                <h6>จุดเด่นของสถาบันอุดมศึกษาตามกลุ่มยุทธศาสตร์
                <OverlayTrigger
                placement="auto"
                delay={{ show: 250, hide: 400 }}
                overlay={renderQuadrantGuideTooltip}
                >
                <img className="ml-2" src="/images/QuestionMark.svg" alt=""/>
                </OverlayTrigger>
                </h6>
                <AlertInfomation type="info" message={quadrantChartInfo}/>
              </Card.Header>
              <Card.Body className="pt-0">
                <ToggleButtonGroup className="profile-quadrant-chart-btn-group" name="quadrantToggleOptions" type="radio" value={this.state.toggleQuadrant} onChange={this.setToggleQuadrant} size="sm">
                  <ToggleButton style={{pointerEvents: this.state.toggleQuadrant === 0 ? "none" : ""}} onClick={this.setToggleQuadrant} value={0}>
                    <FontAwesomeIcon className="mr-2" icon="circle" size="xs"/>
                    Overview
                  </ToggleButton>
                  {
                    this.state.sortedStrategicList.map((strategic,index) => {
                      return (
                        <ToggleButton key={index.toString()} style={{pointerEvents: this.state.toggleQuadrant === index+1 ? "none" : ""}} onClick={this.setToggleQuadrant} value={index+1}>
                        <FontAwesomeIcon className="mr-2" icon="circle" size="xs" color={colorList[index]}/>
                        {strategic}
                        </ToggleButton>
                      );
                    })
                  }
                </ToggleButtonGroup>
                {this.state.toggleQuadrant === 0 ? 
                  <QuardrantAll 
                    id="quardrant_chart_all" 
                    strategicList={this.state.strategicNameList}
                    data={this.state.overViewQuadrant} 
                    abbreviation={profile.abbreviation_en} 
                    type="detail" width= "100%" height="550px"/> : <></>
                }
                {
                  this.state.quadrantChartData.map((strategic,index) => {
                    if(this.state.toggleQuadrant === index+1){
                      return ( 
                        <QuardrantOne id={`quadrant_chart${index}`}
                        key={index.toString()}
                        data={formatData.formatterData(this.state.quadrantChartData[this.state.sortedStrategicIndexList[index]])} 
                        abbreviation={profile.abbreviation_en}
                        color={colorList[index]} 
                        type="detail" width= "100%" height="450px"/>
                      );
                    }
                    else{
                      return <></>
                    }
                  })
                }
              </Card.Body>
            </Card>
          </Col>
        </Row>

        <Row>
          <Col>
            <h6>รายละเอียดการประเมิน
            <OverlayTrigger
              placement="auto"
              delay={{ show: 250, hide: 400 }}
              overlay={renderSunburstTooltip}
            >
              <img className="ml-2" alt="question_mark" src="/images/question_mark_icon.svg" width="16px" height="auto"/>
            </OverlayTrigger>
            </h6>
            <AlertInfomation type="info" message={sunburstChartInfo} fontSize="14px"/>
          </Col>
        </Row>
        <hr/>
        <Row>
          <Col>
            <p className="mt-2">Potential indicators</p>
            <RadarTimeline 
            id="radar_timeline_potential_chart" 
            data={this.state.sortedKpiDataPotential} 
            year={year} 
            strategicNameList={this.state.sortedStrategicList} 
            strategicIdList={this.state.sortedStrategicIndexList} 
            type="potential" width= "100%" height="350px"/>
          </Col>
          <Col>
            <p className="mt-2">Performance indicators</p>
            <RadarTimeline 
            id="radar_timeline_performance_chart" 
            data={this.state.sortedKpiDataPerformance} 
            year={year} 
            strategicNameList={this.state.sortedStrategicList} 
            strategicIdList={this.state.sortedStrategicIndexList} 
            type="performance" width= "100%" height="350px"/>
          </Col>
        </Row>
        <Row className="mt-4 mb-4">
          <ToggleButtonGroup className="progressbar-btn-group" name="progressBarToggleOptions" type="radio" value={this.state.toggleProgressBar} size="sm" onChange={this.setToggleProgressBar}>
            { this.state.sortedStrategicList.map((strategic,index) => {
                return (
                <ToggleButton
                  className={`progressbar-btn-item-${index}`}
                  key={index.toString()}
                  onClick={this.setToggleProgressBar} 
                  value={this.state.sortedStrategicIndexList[index]}
                  style={{pointerEvents: this.state.toggleProgressBar === this.state.sortedStrategicIndexList[index] ? "none" : ""}}>
                    <FontAwesomeIcon color={colorList[index]} className="mr-2" icon="circle" size="xs"/>
                    {strategic}
                </ToggleButton>
                );
              })
            }
          </ToggleButtonGroup >
        </Row>

        <Row>
          {kpiEvaluation.length !== 0 ?
          <Col sm={12} md={6} className="pl-0" style={{height: "458px"}}>
            {kpiEvaluation.map((kpi)=> {
              if(kpi.strategic_id === this.state.strategicIdList[this.state.toggleProgressBar] && kpi.type_en === "Potential"){
              return (
                  <div key={kpi.name_th} className="mr-4">
                    <p>{kpi.name_th}
                      <OverlayTrigger
                        placement="auto"
                        delay={{ show: 250, hide: 400 }}
                        overlay={renderProgressBarTooltip(kpi.definition)}
                      >
                      <img className="ml-2 op-6" src="/images/QuestionMark.svg" alt=""/>
                      </OverlayTrigger>
                    </p>
                    <ProgressBar 
                    className="progress-bar-potential" 
                    variant={`progressbar-index-${this.state.sortedStrategicIdList.indexOf(kpi.strategic_id)}-score-${kpi.score}`} 
                    now={kpi.score} max={5}/>
                    <p className="text-center"><small>{kpi.score}</small></p>
                  </div>
              )}
              else{
                return null
              }
            })}
          </Col> :
          <Col sm={12} md={6}>
            <div style={{height: "458px"}}/>
          </Col>
          }
          { kpiEvaluation.length !== 0 ?
          <Col sm={12} md={6} className="pr-0" style={{height: "458px"}}>
            {kpiEvaluation.map((kpi)=> {
              if(kpi.strategic_id === this.state.strategicIdList[this.state.toggleProgressBar] && kpi.type_en === "Performance"){
              return (
                <div key={kpi.name_th} className="ml-4" >
                  <p>{kpi.name_th}
                    <OverlayTrigger
                      placement="auto"
                      delay={{ show: 250, hide: 400 }}
                      overlay={renderProgressBarTooltip(kpi.definition)}
                    >
                      <img className="ml-2 op-6" src="/images/QuestionMark.svg" alt=""/>
                    </OverlayTrigger>
                  </p>
                  <ProgressBar 
                  className="progress-bar-performance"
                  variant={`progressbar-index-${this.state.sortedStrategicIdList.indexOf(kpi.strategic_id)}-score-${kpi.score}`} 
                  now={kpi.score} max={5}/>
                  <p className="text-center"><small>{kpi.score}</small></p>
                </div>
              )}
              else{
                return null
              }
            })}
          </Col>
            :
          <Col sm={12} md={6}>
            <div style={{height: "458"}}/>
          </Col>
        }
      </Row>
      </Container>
      <ModalCompare
        history={this.props.history}
        modelData={this.state.optionSearchUniversity}
        year={this.state.year}
        show={this.state.modalCompareShow}
        onHide={() => this.setModalCompareUniversityShow(false)}
      />
      {this.state.viewMode === 'detail' ? <YearCard
        slug={this.props.match.params.university}
        currentPage="detail"
        strategicList={this.state.strategicNameList}
        selectYear={this.state.selectedYear}
        yearList={this.state.universityYearList}/> : <></>
      }
      <ModalNotFound  show={modalNotFoundShow}/>
    </div>
    );
  }
}

export default Detail;