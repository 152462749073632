import React, { Component } from 'react';
import { Button, Card, Accordion, Row, Col } from 'react-bootstrap';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEllipsisV } from "@fortawesome/free-solid-svg-icons";
import ModalSelectStrategic from '../component/ModalSelectStrategic';

class YearCard extends Component {
  constructor(props) {
      super(props);
      this.fetchOverall = this.fetchOverall.bind(this);
      this.state = {
        modalSelectStrategicShow: false
      }
  }

  fetchOverall = async(year) => {
    if(this.props.currentPage === "detail"){
      localStorage.clear();
      window.location.assign(`${window.location.origin}/detail/${this.props.slug}/${year}`)
    }
    else{
      localStorage.clear();
      localStorage.setItem('selectedYear', year);
      window.scrollTo(0,0);
      window.location.reload();
    }
  }

  setModalSelectStrategicShow(boo){
    this.setState({
      modalSelectStrategicShow : boo
    })
  }

  render() {
    return (
      <>
      <Accordion defaultActiveKey="0">
        <Card className='card-year'>
          <Card.Header>
            ผลการประเมินปี {localStorage.getItem('selectedYear')}
            <Accordion.Toggle as="button" eventKey="1" className='selection-year-icon ml-1'>
              <FontAwesomeIcon icon={faEllipsisV} color='lightgray'/>
            </Accordion.Toggle>
          </Card.Header>
          <Accordion.Collapse eventKey="1">
            <Card.Body>
              <Card.Title className='card-year-title'>เลือกดูปีการประเมิน</Card.Title>
              <Row>
                {this.props.yearList.map((year,index) => {
                  return <Col xs={4} key={index}><Card.Link className="card-year-link" onClick={() => this.fetchOverall(year)} 
                  style={{
                    pointerEvents: (year === localStorage.getItem('selectedYear')) ? "none" : "", 
                    cursor: (year === localStorage.getItem('selectedYear')) ? "default": "pointer", 
                    fontWeight: (year === localStorage.getItem('selectedYear')) ? "bold": ""}
                  }>
                  {year}
                  </Card.Link></Col>
                })}
              </Row>
              <hr></hr>
              <Row style={{placeContent: "center"}}>
              <Button className="btn-select-strategic" onClick={() => this.setModalSelectStrategicShow(true)}><span style={{fontSize: "16px"}}>เลือกดูกลุ่มยุทธศาสตร์</span></Button>
              </Row>
            </Card.Body>
          </Accordion.Collapse>
        </Card>
      </Accordion>
      <ModalSelectStrategic strategicList={this.props.strategicList} show={this.state.modalSelectStrategicShow} onHide={() => this.setModalSelectStrategicShow(false)} data={[]}/>
      </>
    );
  }
}

export default YearCard;