import React, { Component } from 'react';
import * as am4core from "@amcharts/amcharts4/core";
import * as am4charts from "@amcharts/amcharts4/charts";
import am4themes_animated from "@amcharts/amcharts4/themes/animated";
am4core.addLicense("CH235960823")
am4core.useTheme(am4themes_animated);

class PieChart extends Component {
  
  componentDidMount() {
    this.initChart(); 
  }

  componentWillUnmount() {
    if(this.chart) {
      this.chart.dispose();
    }
  }

  initChart(){
    var score = 0;
    this.props.data.map(data => {
      score += data.value
      return score
    });
    
    var chart = am4core.create(this.props.id, am4charts.PieChart);
    chart.hiddenState.properties.opacity = 0;
    chart.data = this.props.data;
    chart.radius = am4core.percent(60);

    var series = chart.series.push(new am4charts.PieSeries());
    series.dataFields.category = "name";
    series.dataFields.value = "value";
    series.slices.template.propertyFields.fill = "color";
    series.slices.template.showOnInit = true;
    series.slices.template.hiddenState.properties.shiftRadius = 1;
    series.slices.template.tooltipText = "[font-size:14px]{category}: [Bold]{value.percent.formatNumber('#.0a')}%";
    series.labels.template.text = "[font-size:12px]{category}: [Bold]{value.percent.formatNumber('#.0a')}%";
    series.ticks.template.events.on("ready", hideSmall);
    series.ticks.template.events.on("visibilitychanged", hideSmall);
    series.labels.template.events.on("ready", hideSmall);
    series.labels.template.events.on("visibilitychanged", hideSmall);
    function hideSmall(ev) {
      if (ev.target.dataItem && (ev.target.dataItem.values.value.percent <= 0)) {
        ev.target.hide();
      }
      else {
        ev.target.show();
      }
    }

    // var colorSet = new am4core.ColorSet();
    // colorSet.list = ["#FAB319", "#314BFD", "#C1D050", "#E0368C", "#DBDBDB"].map(function(color) {
    //     return new am4core.color(color);
    // });
    // series.colors = colorSet;

    this.chart = chart;
  }
  
  render() {
    return (
      <div className="chart" id={this.props.id} style={{ width: this.props.width, height: this.props.height}}></div>
    );
  }
}  

export default PieChart;