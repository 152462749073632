import React, { Component } from 'react';
import { Button, Modal } from 'react-bootstrap';

class ModalNotFound extends Component {
  constructor(props) {
    super(props);
    this.state = {
    }
  }

  reload(){
    localStorage.clear()
    window.location.reload(false);
  }

  render() {
    return (
      <Modal
        {...this.props}
        size="lg"
        centered
      >
        <Modal.Body>
            <h4 className="mt-2">ไม่สามารถโหลดข้อมูลได้ กรุณาลองใหม่อีกครั้ง
            <Button style={{width: "140px" ,height: "36px"}} className="float-right btn-submit" size="sm" onClick={this.reload}>โหลดใหม่</Button>
            </h4>
        </Modal.Body>
      </Modal>
    );
  }
}

export default ModalNotFound;