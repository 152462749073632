import React, { Component } from 'react';
import {Card, ListGroup, Col, Row} from 'react-bootstrap';

class UniversityDetail extends Component {
    constructor(props) {
        super(props);
        this.state = {
            profile: this.props.profile,
            international_rank: this.props.international_rank
        }
    }
    render() {
        const { profile, international_rank } = this.state
        var qs_rank, the_rank;
        if(international_rank.length){
            international_rank.forEach(web_ranking => {
                if(web_ranking.name === "QS"){
                    qs_rank = web_ranking.rank;
                }
                else if(web_ranking.name === "THE"){
                    the_rank = web_ranking.rank;
                }
            })
        }

        return (
            <Card style={{border: "none"}}>
                <Card.Header style={{background: "#FFFFFF", color: "#161148", border: "none"}}>
                    <img alt="" src={(profile.logo) ? profile.logo: "/images/uclas-logo-default.png"} className="mr-3" width="40px" height="100%"/>
                    {profile.university_th}
                </Card.Header>
                <ListGroup varaint="flush">
                <ListGroup.Item style={{border: "none"}}>
                    <Card.Title className="content-text">ที่ตั้ง</Card.Title>
                    <Card.Text className="desc-text">{(profile.address_th) ? profile.address_th : "-"}</Card.Text>
                </ListGroup.Item>
                <ListGroup.Item style={{border: "none"}}>
                    <Row>
                        <Col>
                            <Card.Title className="content-text">หมายเลขโทรศัพท์</Card.Title>
                            <Card.Text className="desc-text">{(profile.phone) ? profile.phone : "-"}</Card.Text>
                        </Col>
                        <Col>
                            <Card.Title className="content-text">แฟกซ์</Card.Title>
                            <Card.Text className="desc-text">{(profile.fax) ? profile.fax : "-"}</Card.Text>
                        </Col>
                    </Row>
                </ListGroup.Item>
                <ListGroup.Item style={{border: "none"}}>
                    <Row>
                        <Col>
                            <Card.Title className="content-text">อีเมล</Card.Title>
                            <Card.Text className="desc-text">{(profile.email) ? profile.email : "-"}</Card.Text>
                        </Col>
                        <Col>
                            <Card.Title className="content-text">เว็บไซต์</Card.Title>
                            <Card.Text className="desc-text">{(profile.website) ? profile.website : "-"}</Card.Text>
                        </Col>
                    </Row>
                </ListGroup.Item>
                <ListGroup.Item style={{border: "none"}}>
                    <Card.Title className="content-text">อันดับสถาบันอุดมศึกษา</Card.Title>
                    <Card.Text className="desc-text">
                        <img alt="" src={`/images/qs-ranking.svg`} width="30px"/>
                        <span className="desc-text ml-2 mr-2" style={{verticalAlign: "middle", marginBottom: "0.75em"}}>
                            #{(qs_rank) ? qs_rank : "N/A"}
                        </span>
                        <img alt="" src={`/images/world-university-ranking.svg`} width="30px"/>
                        <span className="desc-text ml-2 mr-2" style={{verticalAlign: "middle", marginBottom: "0.75em"}}>
                            #{(the_rank) ? the_rank : "N/A"}
                        </span>
                    </Card.Text>
                </ListGroup.Item>
                <ListGroup.Item style={{border: "none"}}>
                    <Card.Title className="content-text">โซเชียลมีเดีย</Card.Title>
                    <Card.Text className="desc-text">
                        <span className={(profile.facebook) ? "" : "isDisabled"}><a href={profile.facebook ? profile.facebook : "#"} target="_blank" rel="noopener noreferrer"><img alt="facebook-icon" src={"/images/fb-logo.svg"} width="30px" height="30px" className="d-inline-block mr-2"/></a></span>
                        <span className={(profile.twitter) ? "" : "isDisabled"}><a href={profile.twitter ? profile.twitter : "#"} target="_blank" rel="noopener noreferrer"><img alt="twitter-icon" src={"/images/twitter-logo.svg"} width="30px" height="30px" className="d-inline-block mr-2"/></a></span>
                        <span className={(profile.instragram) ? "" : "isDisabled"}><a href={profile.instragram ? profile.instragram : "#"} target="_blank" rel="noopener noreferrer"><img alt="ig-icon" src={"/images/ig-logo.svg"} width="30px" height="30px" className="d-inline-block mr-2"/></a></span>
                        <span className={(profile.linkedin) ? "" : "isDisabled"}><a href={profile.linkedin ? profile.linkedin : "#"} target="_blank" rel="noopener noreferrer"><img alt="linkedin-con" src={"/images/linkedin-logo.svg"} width="30px" height="30px" className="d-inline-block mr-2"/></a></span>
                    </Card.Text>
                </ListGroup.Item>
                </ListGroup>
            </Card>
        );
    }
}

export default UniversityDetail;