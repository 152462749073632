import React, { Component } from 'react';
import * as am4core from "@amcharts/amcharts4/core";
import * as am4charts from "@amcharts/amcharts4/charts";
import am4themes_animated from "@amcharts/amcharts4/themes/animated";
import { isMobile } from "react-device-detect";
am4core.addLicense("CH235960823")
am4core.useTheme(am4themes_animated);

class HorizontallyStacked extends Component {
  constructor(props) {
    super(props);
    this.state = {
      xAxesConfig: {
        "marginRight": 40,
        "renderer": {
          "baseGrid": {
            "disabled": true
          },
          "gridContainer": {
            "background": {
              "fillOpacity": 0.05
            }
          },
          "labels": {
            "template": {
              "fontSize": 12,
              "disabled": true
            }
          }
        },
        "tooltip": {
          "disabled": true
        }
      }
    }
  }

  componentDidMount() {  
    this.initChart(); 
  }

  componentWillUnmount() {
    if(this.chart) {
      this.chart.dispose();
    }
  }

  initChart(){
    function checkDuplicate(datas){
      datas.forEach((data,index) => {
        datas[index].abbreviation_th = `${data.abbreviation_th} (${index})`
      });
      return datas;
    }

    function createGrid(valueAxisNumber, value) {
      var range = valueAxisNumber.axisRanges.create();
      range.value = value;
      if(range.value === 0)
        range.label.text = "min";
      else if(range.value === 6)
        range.label.text = "max";
      else
        range.label.text = "";
    }

    var interfaceColors = new am4core.InterfaceColorSet();

    var chart = am4core.create(this.props.id, am4charts.XYChart);

    var { language, horizontallyData } = this.props
    var { xAxesConfig } = this.state

    var inputData = checkDuplicate(horizontallyData);

    chart.data = inputData;
    chart.numberFormatter.numberFormat = "#.##";

    // the following line makes value axes to be arranged vertically.
    chart.bottomAxesContainer.layout = "horizontal";
    chart.bottomAxesContainer.reverseOrder = true;
    chart.paddingTop = 80;
    chart.paddingBottom = 0;
    chart.responsive.enabled = true;
    
    var categoryAxis = chart.yAxes.push(new am4charts.CategoryAxis());
    categoryAxis.dataFields.category = "abbreviation_" + language ;
    categoryAxis.renderer.grid.template.stroke = interfaceColors.getFor("background");
    categoryAxis.renderer.grid.template.strokeOpacity = 1;
    categoryAxis.renderer.grid.template.location = 1;
    categoryAxis.renderer.minGridDistance = 40;
    categoryAxis.tooltip.disabled = true;
    categoryAxis.renderer.labels.template.adapter.add("textOutput", (text) => {
      if(typeof text !== 'undefined'){
        return text.replace(/ \(.*/, "");
      }
      return text;
    });

    categoryAxis.renderer.labels.template.propertyFields.url = "url"
    categoryAxis.renderer.labels.template.urlTarget = "_blank";

    var valueAxis1 = chart.xAxes.push(new am4charts.ValueAxis());
    valueAxis1.config = xAxesConfig;
    valueAxis1.min = 0;
    valueAxis1.max = 6;
    valueAxis1.renderer.minGridDistance = 40;
    valueAxis1.renderer.gridContainer.background.fill = interfaceColors.getFor("alternativeBackground");
    valueAxis1.renderer.grid.template.stroke = interfaceColors.getFor("background");
    valueAxis1.renderer.grid.template.strokeOpacity = 1;
    valueAxis1.title.text = this.props.strategicList[localStorage.getItem('strategic1')];
    valueAxis1.title.fontSize = isMobile? "10px":"12px"
    valueAxis1.title.align = "left";
    createGrid(valueAxis1,0);
    createGrid(valueAxis1,6);

    var series1 = chart.series.push(new am4charts.LineSeries());
    series1.dataFields.categoryY = "abbreviation_" + language ;
    series1.dataFields.openValueX = "performance1";
    series1.dataFields.valueX = "potential1";
    series1.xAxis = valueAxis1;
    series1.tooltipText = "[Bold]{university_th}:[/]\n Potential: [Bold]{valueX.value}[/] Performance: [Bold]{openValueX.value}[/]";
    series1.tooltip.getFillFromObject = false;
    series1.tooltip.background.fill = am4core.color("#FAB319");
    series1.tooltip.label.fill = am4core.color("#FFFFFF");
    series1.sequencedInterpolation = true;
    series1.fillOpacity = 0;
    series1.strokeOpacity = 0;
    series1.sequencedInterpolation = true;
    series1.sequencedInterpolationDelay = 0.01;
    series1.tooltip.pointerOrientation = "vertical";

    var performanceBullet1 = series1.bullets.create(am4charts.CircleBullet);
    performanceBullet1.locationX = 1;
    performanceBullet1.fill = am4core.color("#FAB319");
    performanceBullet1.stroke = am4core.color("#FAB319");
    performanceBullet1.circle.adapter.add("radius", (radius,target) => {
      return radius + (target.dataItem.openValueX * 2)
    });

    var potentialBullet1 = series1.bullets.create(am4charts.CircleBullet);
    potentialBullet1.stroke = potentialBullet1.fill;
    potentialBullet1.fill = am4core.color("#F9DDA3");
    potentialBullet1.stroke = am4core.color("#F9DDA3");
    potentialBullet1.circle.adapter.add("radius", (radius,target) => {
      return radius + (target.dataItem.valueX * 2)
    });
    
    var valueAxis2 = chart.xAxes.push(new am4charts.ValueAxis());
    valueAxis2.config = xAxesConfig;
    valueAxis2.min = 0;
    valueAxis2.max = 6;
    valueAxis2.renderer.minGridDistance = 40;
    valueAxis2.renderer.gridContainer.background.fill = interfaceColors.getFor("alternativeBackground");
    valueAxis2.renderer.grid.template.stroke = interfaceColors.getFor("background");
    valueAxis2.renderer.grid.template.strokeOpacity = 1;
    valueAxis2.title.text = this.props.strategicList[localStorage.getItem('strategic2')];
    valueAxis2.title.fontSize = isMobile? "10px":"12px"
    valueAxis2.title.align = "left";
    createGrid(valueAxis2,0);
    createGrid(valueAxis2,6);
    
    var series2 = chart.series.push(new am4charts.LineSeries());
    series2.dataFields.categoryY = "abbreviation_" + language ;
    series2.dataFields.openValueX = "performance2";
    series2.dataFields.valueX = "potential2";
    series2.xAxis = valueAxis2;
    series2.tooltipText = "Potential: [Bold]{valueX.value}[/] Performance: [Bold]{openValueX.value}[/]";
    series2.tooltip.getFillFromObject = false;
    series2.tooltip.background.fill = am4core.color("#314BFD");
    series2.tooltip.label.fill = am4core.color("#FFFFFF");
    series2.sequencedInterpolation = true;
    series2.fillOpacity = 0;
    series2.strokeOpacity = 0;
    series2.sequencedInterpolation = true;
    series2.sequencedInterpolationDelay = 0.01;
    series2.tooltip.pointerOrientation = "vertical";

    var performanceBullet2 = series2.bullets.create(am4charts.CircleBullet);
    performanceBullet2.locationX = 1;
    performanceBullet2.fill = am4core.color("#314BFD");
    performanceBullet2.stroke = am4core.color("#314BFD");
    performanceBullet2.circle.adapter.add("radius", (radius,target) => {
      return radius + (target.dataItem.openValueX * 2)
    });

    var potentialBullet2 = series2.bullets.create(am4charts.CircleBullet);
    potentialBullet2.fill = am4core.color("#9EABFF");
    potentialBullet2.stroke = am4core.color("#9EABFF");
    potentialBullet2.stroke = potentialBullet2.fill;
    potentialBullet2.circle.adapter.add("radius", (radius,target) => {
      return radius + (target.dataItem.valueX * 2)
    });

    var valueAxis3 = chart.xAxes.push(new am4charts.ValueAxis());
    valueAxis3.config = xAxesConfig;
    valueAxis3.min = 0;
    valueAxis3.max = 6;
    valueAxis3.renderer.minGridDistance = 40;
    valueAxis3.renderer.gridContainer.background.fill = interfaceColors.getFor("alternativeBackground");
    valueAxis3.renderer.grid.template.stroke = interfaceColors.getFor("background");
    valueAxis3.renderer.grid.template.strokeOpacity = 1;
    valueAxis3.title.text = this.props.strategicList[localStorage.getItem('strategic3')];
    valueAxis3.title.fontSize = isMobile? "10px":"12px"
    valueAxis3.title.align = "left";
    createGrid(valueAxis3,0);
    createGrid(valueAxis3,6);

    var series3 = chart.series.push(new am4charts.LineSeries());
    series3.dataFields.categoryY = "abbreviation_" + language ;
    series3.dataFields.openValueX = "performance3";
    series3.dataFields.valueX = "potential3";
    series3.xAxis = valueAxis3;
    series3.tooltipText = "Potential: [Bold]{valueX.value}[/] Performance: [Bold]{openValueX.value}[/]";
    series3.tooltip.getFillFromObject = false;
    series3.tooltip.background.fill = am4core.color("#C1D050");
    series3.tooltip.label.fill = am4core.color("#FFFFFF");
    series3.sequencedInterpolation = true;
    series3.fillOpacity = 0;
    series3.strokeOpacity = 0;
    series3.sequencedInterpolation = true;
    series3.sequencedInterpolationDelay = 0.01;
    series3.tooltip.pointerOrientation = "vertical";

    var performanceBullet3 = series3.bullets.create(am4charts.CircleBullet);
    performanceBullet3.locationX = 1;
    performanceBullet3.fill = am4core.color("#C1D050");
    performanceBullet3.stroke = am4core.color("#C1D050");
    performanceBullet3.circle.adapter.add("radius", (radius,target) => {
      return radius + (target.dataItem.openValueX * 2)
    });

    var potentialBullet3 = series3.bullets.create(am4charts.CircleBullet);
    potentialBullet3.fill = am4core.color("#D8E282");
    potentialBullet3.stroke = am4core.color("#D8E282");
    potentialBullet3.stroke = potentialBullet3.fill;
    potentialBullet3.circle.adapter.add("radius", (radius,target) => {
      return radius + (target.dataItem.valueX * 2)
    });


    var valueAxis4 = chart.xAxes.push(new am4charts.ValueAxis());
    valueAxis4.config = xAxesConfig;
    valueAxis4.min = 0;
    valueAxis4.max = 6;
    valueAxis4.renderer.minGridDistance = 40;
    valueAxis4.renderer.gridContainer.background.fill = interfaceColors.getFor("alternativeBackground");
    valueAxis4.renderer.grid.template.stroke = interfaceColors.getFor("background");
    valueAxis4.renderer.grid.template.strokeOpacity = 1;
    valueAxis4.title.text = this.props.strategicList[localStorage.getItem('strategic4')];
    valueAxis4.title.fontSize = isMobile? "10px":"12px"
    valueAxis4.title.align = "left";
    createGrid(valueAxis4,0);
    createGrid(valueAxis4,6);

    var series4 = chart.series.push(new am4charts.LineSeries());
    series4.dataFields.categoryY = "abbreviation_" + language ;
    series4.dataFields.openValueX = "performance4";
    series4.dataFields.valueX = "potential4";
    series4.xAxis = valueAxis4;
    series4.tooltipText = "Potential: [Bold]{valueX.value}[/] Performance: [Bold]{openValueX.value}[/]";
    series4.tooltip.getFillFromObject = false;
    series4.tooltip.background.fill = am4core.color("#E0368C");
    series4.tooltip.label.fill = am4core.color("#FFFFFF");
    series4.sequencedInterpolation = true;
    series4.fillOpacity = 0;
    series4.strokeOpacity = 0;
    series4.sequencedInterpolation = true;
    series4.sequencedInterpolationDelay = 0.01;
    series4.tooltip.pointerOrientation = "vertical";

    var performanceBullet4 = series4.bullets.create(am4charts.CircleBullet);
    performanceBullet4.locationX = 1;
    performanceBullet4.fill = am4core.color("#E0368C");
    performanceBullet4.stroke = am4core.color("#E0368C");
    performanceBullet4.circle.adapter.add("radius", (radius,target) => {
      return radius + (target.dataItem.openValueX * 2)
    });

    var potentialBullet4 = series4.bullets.create(am4charts.CircleBullet);
    potentialBullet4.fill = am4core.color("#DDADC5");
    potentialBullet4.stroke = am4core.color("#DDADC5");
    potentialBullet4.stroke = potentialBullet4.fill;
    potentialBullet4.circle.adapter.add("radius", (radius,target) => {
      return radius + (target.dataItem.valueX * 2)
    });

    // Set cell size in pixels
    var cellSize = 50;
    chart.events.on("datavalidated", function(ev) {
      
      // Get objects of interest
      var chart = ev.target;
      var categoryAxis = chart.yAxes.getIndex(0);
      
      // Calculate how we need to adjust chart height
      var adjustHeight = chart.data.length * cellSize - categoryAxis.pixelHeight;
      
      // get current chart height
      var targetHeight = chart.pixelHeight + adjustHeight;
      
      // Set it on chart's container
      chart.svgContainer.htmlElement.style.height = targetHeight + "px";

      // Set label heigth
      valueAxis1.title.dy = ((-1) * targetHeight)+60;
      valueAxis1.renderer.labels.template.dy = ((-1) * targetHeight)+110;
      valueAxis2.title.dy = ((-1) * targetHeight)+60;
      valueAxis2.renderer.labels.template.dy = ((-1) * targetHeight)+110;
      valueAxis3.title.dy = ((-1) * targetHeight)+60;
      valueAxis3.renderer.labels.template.dy = ((-1) * targetHeight)+110;
      valueAxis4.title.dy = ((-1) * targetHeight)+60;
      valueAxis4.renderer.labels.template.dy = ((-1) * targetHeight)+110;
    });
    
    chart.cursor = new am4charts.XYCursor();
    chart.cursor.behavior = "none";
    chart.cursor.lineY.disabled = true;
    chart.cursor.lineX.disabled = true;
    

    this.chart = chart;
  }

  render() {
    return (
      <div className="chart" id={this.props.id} style={{ width: this.props.width, height: this.props.height}}></div>
    );
  }
}

export default HorizontallyStacked;